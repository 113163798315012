import React from 'react';
import Modal from 'react-modal';
import style from './Dialog.module.scss';
import { ReactComponent as Close } from '../../assets/svg/close_dialog.svg';

const customStyles = {
    overlay: {
        display: 'flex',
        backgroundColor: 'rgba(0,0,0,0.75)',
        transition: 'all 500ms ease-in-out',
        zIndex: 11,
    },
    content: {
        width: '100%',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
    },
};

export default function Dialog(props) {
    const { closeModal, state, children } = props;

    return (
        <div>
            <Modal
                isOpen={state}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                className={style.container}
            >
                <div className={style.wrapperDialog}>
                    <div className={style.wrapperTitle}>
                        <p>Last 5 Flips</p>
                        <div
                            className={style.wrapperClose}
                            onClick={closeModal}
                        >
                            <Close />
                        </div>
                    </div>
                    <div className={style.wrapperContent}>{children}</div>
                </div>
            </Modal>
        </div>
    );
}

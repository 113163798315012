import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    connectMetaMask,
    connectToWalletConnect
} from './actions';

import { CONNECTION_TYPES } from '../../../components/ConnectToWalletModal/constants'

export function UseUserInit() {
    const dispatch = useDispatch();

    const { isMobile } = useSelector(state => state.app);

    // Init wallet
    useEffect(() => {
        dispatch(connectWallet(isMobile));
    }, [dispatch]);
}

export const connectWallet = (isMobile) => dispatch => {
    const connectorId = window.localStorage.getItem('connectorId');
    if (connectorId === CONNECTION_TYPES.metamask) {
        dispatch(connectMetaMask(isMobile));
    } else if (connectorId === CONNECTION_TYPES.walletconnect) {
        dispatch(connectToWalletConnect());
    }
}
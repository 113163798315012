import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Header from './Header';

import { getAccountSymbol } from '../../services/web3';
import { setConnectModalVisible } from '../../store/modules/App/actions';
// import {
//     validateNetwork,
//     setWrongNetwork,
// } from '../../store/modules/User/actions';

export default function HeaderContainer(props) {
    /*
      Use container to interact with external data sources (store, api, etc.)
    */

    const dispatch = useDispatch();

    const { isMobile, isTablet } = useSelector((state) => state.app);
    const isConnected = useSelector(
        (state) => state.user?.connectWallet.isConnect
    );
    const address = useSelector((state) =>
        state.user.userAccount.accounts
            ? state.user.userAccount.accounts[0]
            : ''
    );
    const chainId = useSelector((state) => state.user.chainId);
    const balance = useSelector(
        (state) => state.user?.userAccount.balance ?? 0
    );
    // const wrongNetwork = useSelector((state) => state.user?.wrongNetwork);
    const symbol = getAccountSymbol(chainId);

    // proxy smart contract
    // to do can refactor logic check metamask
    // useEffect(async () => {
    //     if (parseInt(process.env.REACT_APP_CHAIN_ID) === parseInt(chainId)) {
    //         return dispatch(setWrongNetwork(false));
    //     }
    //     return dispatch(validateNetwork(chainId));
    // }, [dispatch, chainId, wrongNetwork]);

    const onConnectClick = () => {
        dispatch(setConnectModalVisible(true));
    };

    return (
        <Header
            {...props}
            {...{
                isMobile,
                isTablet,
                isConnected,
                address,
                balance,
                chainId,
                symbol,
                onConnectClick,
            }}
        />
    );
}

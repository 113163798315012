import React from 'react'
import cn from 'classnames'
import style from './Spinner.module.scss'
import spinner from './spinner.png'

export default function Spinner(props) {
    const { wrapperClass } = props

    return <img src={spinner} alt="" className={cn(wrapperClass, style.container)} />
}

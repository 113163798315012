import React from 'react';
import { useSelector /*, useDispatch*/ } from 'react-redux';
import Header from './Header';

export default function HeaderContainer(props) {
    /*
      Use container to interact with external data sources (store, api, etc.)
    */
    // const dispatch = useDispatch();

    const { isMobile, isTablet } = useSelector((state) => state.app);
    const isConnected = useSelector(
        (state) => state.user?.connectWallet.isConnect
    );

    return (
        <Header
            {...props}
            {...{
                isMobile,
                isTablet,
                isConnected,
            }}
        />
    );
}

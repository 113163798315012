
import AngelChilling from '../../../assets/emotion/Angel Chilling.gif';
import AngelChoosing from '../../../assets/emotion/Angel Chosen.gif';
import AngelAfterChosen from '../../../assets/emotion/Angel After Chosen.gif';
import AngelLosing from '../../../assets/emotion/Angel Losing.gif';
import DevilChilling from '../../../assets/emotion/Devil Chilling.gif';
import DevilChoosing from '../../../assets/emotion/Devil Chosen.gif';
import DevilAfterChosen from '../../../assets/emotion/Devil After Chosen.gif';
import DevilLosing from '../../../assets/emotion/Devil Losing.gif';
import BG_Blue from '../../../lotties/BG_Blue.json';
import BG_Red from '../../../lotties/BG_Red.json';

export const EMOTION_LIST = {
    angel: [
        {
            gif: AngelChilling,
            key: 0,
        },
        {
            gif: AngelChoosing,
            key: 1,
        },
        {
            gif: AngelAfterChosen,
            key: 2,
        },
        {
            gif: AngelLosing,
            key: 3,
        }
    ],
    devil: [
        {
            gif: DevilChilling,
            key: 0
        },
        {
            gif: DevilChoosing,
            key: 1
        },
        {
            gif: DevilAfterChosen,
            key: 2
        },
        {
            gif: DevilLosing,
            key: 3
        }
    ],
    bg: {
        angel: BG_Blue,
        devil: BG_Red,
    },
    direction: {
        0: 'left',
        1: 'right',
    }
}
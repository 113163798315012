import React, { useState } from 'react';
import Footer from '../../components/Footer';
import { HeaderContainer } from '../../components/Header';
import cn from 'classnames';

import style from './PageLayout.module.scss';

export default function PageLayout(props) {
    /*
        Only the layout level components or tasks is allowed here.
        Use an App component to place any global level component or task
    */
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={cn(style.container)}>
            <div
                className={cn(style.fade, { [style.fadeActive]: isOpen })}
                onClick={() => setIsOpen(false)}
            />
            <HeaderContainer
                menuOpen={isOpen}
                onHamburgerClick={() => setIsOpen(!isOpen)}
            />
            <main className={style.main}>{props.children}</main>
            <Footer />
        </div>
    );
}

import Web3 from 'web3';
import { get } from 'lodash'
import { getContractCatch } from '../store/modules/Project/action'
import erc20Abi from '../store/constants/erc20.abi.json'

export const getContract = (abi, address) => {
    const _web3 = new Web3(window.web3.currentProvider)
    return new _web3.eth.Contract(abi, address)
}

export const fetchTokenInfo = async tokenAddress => {
    const contract = getContractCatch(erc20Abi, tokenAddress)
    const data = await Promise.all([
        contract.methods.name().call(),
        contract.methods.symbol().call(),
        contract.methods.decimals().call(),
        contract.methods.totalSupply().call(),
    ])
    return {
        tokenAddress,
        name: get(data, '0'),
        symbol: get(data, '1'),
        decimals: get(data, '2'),
        totalSupply: get(data, '3'),
    }
}

export const getNetworkInfo = (chainId = null) => {
    if (!chainId) {
        chainId = get(window, 'ethereum.chainId')
    }
    const data = {
        '0x61': {
            // bsc test net
            network: 'bsc',
            chainId: '0x61',
            projectContractAddress: process.env.REACT_APP_PROJECT_CONTRACT_BSC_TESTNET,
            projectPoolContractAddress: process.env.REACT_APP_POOL_CONTRACT_BSC_TESTNET,
            lockContractAddress: process.env.REACT_APP_LOCK_CONTRACT_BSC_TESTNET,
            pancakeSwapContract: process.env.REACT_APP_PANCAKE_CONTRACT_BSC_TESTNET,
            wrappedBnbToken: process.env.REACT_APP_PANCAKE_WBNB_CONTRACT_BSC_TESTNET,
        },
        '0x38': {
            network: 'bsc',
            chainId: '0x38',
            projectContractAddress: process.env.REACT_APP_PROJECT_CONTRACT_BSC_MAINNET,
            projectPoolContractAddress: process.env.REACT_APP_POOL_CONTRACT_BSC_MAINNET,
            lockContractAddress: process.env.REACT_APP_LOCK_CONTRACT_BSC_MAINNET,
            pancakeSwapContract: process.env.REACT_APP_PANCAKE_CONTRACT_BSC_MAINNET,
            wrappedBnbToken: process.env.REACT_APP_PANCAKE_WBNB_CONTRACT_BSC_MAINNET,
        },
    }
    if (!data[chainId]) {
        // console.error('dont support chainId: ' + chainId)
        return {}
    }
    return data[chainId]
}


export const contractCall = async (contract, method, defaultVal, ...params) => {
    try {
        return await contract.methods[method](...params)
            .call()
            .catch(() => {
                // console.error('contractCall fail', method, params, ex.message)
                return defaultVal
            })
    } catch (ex) {
        // log ex, prevent app die
        // console.error('contractCall fail', method, params, ex.message)
        return Promise.resolve(defaultVal)
    }
}


export const contractSend = (contract, method, account, ...params) => {
    try {
        // console.info('params contractSend', method, params)
        return contract.methods[method](...params).send({ from: account })
    } catch (ex) {
        // log ex, prevent app die
        // console.error('contractSend fail', method, ex.message)
        // throw ex
        // return Promise.resolve(null)
    }
}
import React from 'react';
import Lottie from 'react-lottie';

import style from './Emoji.module.scss';

export default function Emoji(props) {
    const { bg, frame = 0, emotion } = props;

    return (
        <div className={style.wrapperEmoji}>
            <div className={style.wrapperBackground}>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: bg,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                        },
                    }}
                    height={'100%'}
                    width={'100%'}
                />
            </div>
            <div className={style.emotion}>
                <img src={emotion[frame].gif} alt="animation"></img>
            </div>
        </div>
    );
}

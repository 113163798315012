import React from 'react';
import { Button } from 'react-bootstrap';
import style from './MasterWallet.module.scss';
import cn from 'classnames';
import web3 from 'web3';
import Helper from '../Helper';
import { formatAmount } from '../../utils';
import { ReactComponent as Coin } from '../../assets/svg/coin-silver-1.svg';
import { ReactComponent as Bnb } from '../../assets/svg/bnb.svg';
import { ReactComponent as Icon } from '../../assets/svg/forma-wallet.svg';

function numberWithCommas(value) {
    if (value > 0) {
        const formatUsd = (+`${value ? value : 0}`).toFixed(3);
        if (formatUsd.slice(formatUsd.length - 3) === '000') {
            return `~${formatUsd}`;
        }
        return formatUsd;
    }
    return 0;
}

export default function MasterWallet(props) {
    const { context, onPastResult, onContractAddress, devilBank } = props;

    return (
        <div className={style.wrapperWalletMaster}>
            <div className={style.container}>
                <div className={style.spaceContainer}>
                    <div className={style.title}>
                        <Helper text="">
                            <div className={style.wrapperTitle}>
                                <p>Master Wallet Holdings</p>
                                <Icon />
                            </div>
                        </Helper>
                    </div>
                    <div className={style.content}>
                        <div className={style.contentItem}>
                            <div className={style.contentIcon}>
                                <Coin />
                                <div className={style.iconText}>
                                    <span>
                                        {`${devilBank} DFLIP`}
                                    </span>
                                </div>
                            </div>
                            <div className={style.contentPrice}>
                                {`($${
                                    context && context.devilBank > 0
                                        ? numberWithCommas(
                                            web3.utils.fromWei(
                                                context.devilBank,
                                                'ether'
                                            ) * context.currentPrice
                                        )
                                        : 0
                                })`}
                            </div>
                        </div>
                        <div className={style.contentItem}>
                            <div className={style.contentIcon}>
                                <Bnb />
                                <div className={style.iconText}>
                                    <span>
                                        {`${
                                            context && context.angelBank > 0
                                                ? formatAmount(
                                                    context.angelBank
                                                )
                                                : 0
                                        } BNB`}
                                    </span>
                                </div>
                            </div>
                            <div className={style.contentPrice}>
                                {`($${
                                    context && context.angelBank > 0
                                        ? numberWithCommas(
                                            web3.utils.fromWei(
                                                context.angelBank,
                                                'ether' * context.currentPrice
                                            )
                                        )
                                        : 0
                                })`}
                            </div>
                        </div>
                    </div>
                    <div className={style.action}>
                        <div className={cn(style.devilflip, style.devil2)}>
                            <Button
                                variant="outline-info"
                                onClick={onPastResult}
                            >
                                <span>See Past Results</span>
                            </Button>
                        </div>
                        <div className={cn(style.devilflip, style.devil1)}>
                            <Button
                                variant="outline-info"
                                onClick={onContractAddress}
                            >
                                <span>Contract Address</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from 'react';
import './style.scss';
import { Image } from 'react-bootstrap';

const Devil_Pumps = () => {
    return (
        <section className="devil-pumps devilflip-section pt-0 pb-0">
            <div className="devil-pumps container">
                <div className="row align-items-center">
                    <div className="col-lg-6  text-center text-lg-start custom-col-1">
                        <div className="title-img">
                            <Image src="/assets/images/title/4.png" className="" />
                        </div>
                        <p className="devilflip-paragraph mb-md-0">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.
                        </p>
                    </div>
                    <div className="col-lg-6 mt-sm-3 mt-lg-0 custom-col-2">
                        <video
                            loop={true}
                            autoPlay={true}
                            muted={true}
                            playsInline={true}
                            className="video-player devilflip-section-image"
                        >
                            <source
                                src={`${process.env.PUBLIC_URL}/assets/images/devil_pumps.mp4`}
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Devil_Pumps;

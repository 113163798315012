import Web3 from 'web3'
import pancakeFactoryContractAbi from '../store/constants/pancakeFactoryContractAbi.json'
import pancakePairContractAbi from '../store/constants/pancakePairContractAbi.json'
import { formatUnits } from '../store/constants/web3'
import { getNetworkInfo } from '../utils/contractHelpers'

// config for test net

export const getCurrentPrice = (tokenAddress, tokenDecimals, usdPrice, chainId) => async () => {
    try {
        // init instance web3 provider
        window.web3 = new Web3(window.web3.currentProvider)
        // get Pancake Swap contract and call getPair() method
        const network = getNetworkInfo(chainId)
        const pancakeContractToken = new window.web3.eth.Contract(pancakeFactoryContractAbi, network.pancakeSwapContract)
        tokenAddress = Web3.utils.toChecksumAddress(tokenAddress)
        // console.log('liquidityAddress', tokenAddress, wrappedBnbToken)
        const liquidityAddress = await pancakeContractToken.methods.getPair(tokenAddress, network.wrappedBnbToken).call()

        // console.log('liquidityAddress', liquidityAddress)
        // get Liquidity Contract and call getReserves() method
        const liquidityContractToken = new window.web3.eth.Contract(pancakePairContractAbi, liquidityAddress || '')
        const reserves =
            liquidityAddress !== '0x0000000000000000000000000000000000000000'
                ? await liquidityContractToken.methods.getReserves().call()
                : 0

        // get token1 from reserves result
        const token1 =
            liquidityAddress !== '0x0000000000000000000000000000000000000000'
                ? await liquidityContractToken.methods.token1().call()
                : ''

        const price = calcPriceFromliquidityPair(token1, reserves['0'], reserves['1'], tokenDecimals, usdPrice, chainId)

        return price
    } catch (error) {
        return null
    }
}

export function calcPriceFromliquidityPair(tokenWbnb, reserves0, reserves1, tokenDecimals, usdPrice, chainId) {
    let currentPrice = 0
    const network = getNetworkInfo(chainId)
    if (tokenWbnb === network.wrappedBnbToken) {
        currentPrice = (+formatUnits(reserves1, 18) / +formatUnits(reserves0, tokenDecimals)) * usdPrice
    } else {
        currentPrice = (+formatUnits(reserves0, 18) / +formatUnits(reserves1, tokenDecimals)) * usdPrice
    }

    const price = currentPrice ? Number(currentPrice).toFixed(14) : 0

    return price
}

import React from 'react';
import { Button } from 'react-bootstrap';
import cn from 'classnames';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { ReactComponent as Directional } from '../../assets/svg/directional.svg';
import { ReactComponent as Circle } from '../../assets/svg/circle.svg';

import style from './Spinner.module.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#282828' }} spin />;

const animationAction = {
    default: style.default,
    balance: style.balance,
    left: style.left,
    right: style.right,
};

export default function Spinner(props) {
    const {
        direction,
        onSpin,
        canSpin,
        isSpinning,
        isSpinLoading
    } = props;

    return (
        <div className={style.container}>
            <div className={style.spaceContainer}>
                <div className={style.circle}>
                    <div className={style.outline}>
                        <div className={style.subCircle}>
                            <div
                                className={cn(
                                    style.wrapperDirectional,
                                    animationAction[direction]
                                )}
                            >
                                <Directional />
                            </div>
                            <div className={style.wrapperCircle}>
                                <Circle />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={cn(
                        style.wrapperAction,
                        canSpin ? null : style.disable
                    )}
                    style={{ pointerEvents: isSpinning && 'none', cursor: isSpinning && 'not-allowed' }}
                >
                    <>
                        <Button
                            disabled={isSpinning}
                            variant="outline-info"
                            onClick={() => onSpin()}
                        >
                            {isSpinLoading ? (
                                <Spin indicator={antIcon} />
                            ) : (
                                <span>Spin</span>
                            )}
                        </Button>
                    </>
                </div>
            </div>
        </div>
    );
}

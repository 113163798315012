export const GAME_LIST = {
    0: {
        title: 'Till Decision is Made',
    },
    1: {
        title: 'Buying in Progress',
        subTitle: 'Angel has taken control over the master wallet.',
    },
    2: {
        title: 'Selling in Progress',
        subTitle: 'Devil has taken control over the master wallet.',
    },
    css: {
        title: {
            fontSize: '37.5px',
        },
        subTitle: {
            fontSize: '12.5px',
            paddingTop: '18.5px',
        },
    },
    href: `${process.env.REACT_APP_BLOCK_EXPLORER_URL_BSC_TESTNET}/address/${process.env.REACT_APP_ADDRESS} `,
    limit: process.env.REACT_APP_ALLOW_SPIN || 10
};

export const SECONDS_DURATION_AFTER_HAS_RESULT = 15;

export const SPIN_DIRECTION = {
    BALANCE: 'balance',
    LEFT: 'left',
    RIGHT: 'right',
    DEFAULT: 'default'
}

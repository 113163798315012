import React from 'react';
import Logger from 'js-logger';

import Router from './router';
import { PAGE_GAME_PATH } from './router/constants';

import { UseAppInit } from './store/modules/App/hooks';
import { UseUserInit } from './store/modules/User/hooks';
import { UseRoundHooks } from './store/modules/Game/hooks';
import { LoaderContainer } from './components/Loader';
import ModalContainer from './components/ContainerModal';
// import { WrongNetworkModalContainer } from './components/WrongNetworkModal';

import './App.scss';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

function App() {
    /*
        Any global level component or task should be defined here
    */
    Logger.useDefaults();

    // Initiate state
    UseAppInit();
    UseUserInit();
    window.location.pathname === PAGE_GAME_PATH && UseRoundHooks();

    return (
        <>
            <Router />
            <LoaderContainer />
            {window.location.pathname === PAGE_GAME_PATH && <ModalContainer />}
            {/* <WrongNetworkModalContainer/> */}
        </>
    );
}

export default App;

import { createAction } from 'redux-actions';

export const setCycleInfo = createAction('SET_ROUND_DATA');
export const setCountDown = createAction('SET_COUNT_DOWN');
export const setAutoSpin = createAction('SET_AUTO_SPIN');
export const setManualSpin = createAction('SET_MANUAL_SPIN');
export const setPrevCycleInfo = createAction('SET_PREV_CYCLE_INFO');

export const updateCycleInfo = (initValue, index, cycle) => (dispatch) => {
    dispatch(setCycleInfo({ initValue, index, cycle }));
};

export const fetchPrevCycleInfo = (data) => (dispatch) => {
    dispatch(setPrevCycleInfo(data));
};
// import { useSelector } from 'react-redux';
import web3Lib from 'web3';

// import { contractAddress } from './constants';

import devilPresenter from '../../abi/devilflip_presenter.abi.json';
import devilToken from '../../abi/devilflip_token.abi.json';

let web3Connect = null;
let web3 = null;


try {
    // const chainId = useSelector(state => state.wallet?.chainId);
    web3 = new web3Lib(window.web3.currentProvider);
    // eslint-disable-next-line no-unused-vars
    web3Connect = new web3.eth.Contract(devilPresenter, process.env.REACT_APP_LOCK_CONTRACT_BSC_TESTNET);
}
catch {
    null;
}

export default class GameApi {
    static contractApi(method, action, defaultVal, ...params) {
        try {
            const fn = eval(
                `web3Connect.methods.${method}(${params}).${action}();`
            )
            return fn;
        }
        catch (err) {
            return defaultVal
        }
    }

    static async fetchTokenDecimals() {
        try {
            const tokenAddress = await this.contractApi('token', 'call', null);
            const web3ConnectSumAddress = new web3.eth.Contract(devilToken, web3.utils.toChecksumAddress(tokenAddress));
            return await web3ConnectSumAddress.methods.decimals().call();
        }
        catch {
            return '0';
        }
    }

    static async fetchAddress() {
        return await this.contractApi('admin', 'call', '0');
    }

    static async fetchTokenAddress() {
        return await this.contractApi('token', 'call', '0');
    }

    static async fetchCycleIndex() {
        return await this.contractApi('cycleIndex', 'call', '0');
    }

    static async fetchCyclePresenter(cycleIndex) {
        return await this.contractApi('cycles', 'call', '0', cycleIndex);
    }

    static async fetchCanSpin() {
        return await this.contractApi('canSpin', 'call', '0');
    }

    static async fetchDevilBank() {
        return await this.contractApi('devilBank', 'call', '0');
    }

    static async fetchAngelBank() {
        return await this.contractApi('angelBank', 'call', '0');
    }

    static async fetchSpinVRF(senderAddress) {
        return await this.contractApi('spinVRF', 'send', '0', { from: senderAddress })
    }

    static async fetchIsSpin() {
        return await this.contractApi('isSpinning', 'call', '0');
    }

    static async fetchHistory(cycleIndex, limit, fn) {
        try {
            const result = [];
            for (let i = cycleIndex < 5 ? 0 : cycleIndex - limit; i < cycleIndex; i++) {
                result.push(await fn(i));
            }
            return await result.reverse();
        }
        catch {
            return '0';
        }
    }
}
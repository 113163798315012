import React, { memo } from 'react';
import style from './CountDown.module.scss';

export default memo(function CountDown(props) {
    const {
        getTimeCountDown,
        children,
        css,
    } = props;

    const hours = getTimeCountDown ? getTimeCountDown().hours : '00';
    const minutes = getTimeCountDown ? getTimeCountDown().minutes : '00';
    const seconds = getTimeCountDown ? getTimeCountDown().seconds : '00';

    return (
        <div className={style.container} style={{ css }}>
            {getTimeCountDown && (
                <span className={style.span}>
                    {`${hours}:${minutes}:${seconds}`}
                </span>
            )}

            <div className={style.wrapperChildNode}>{children}</div>
        </div>
    );
});

import Web3 from 'web3'
import projectAbi from '../../abi/projectContractAbi.json'
import http from '../http'
export default class User {
    static buyToken({ accountAddress, contractAddress, amount, callback }) {
        const contract = new window.web3.eth.Contract(projectAbi, contractAddress)
        const buyTokenData = contract.methods.buyTokens().encodeABI()
        const rawTransaction = {
            value: amount,
            data: buyTokenData,
            from: accountAddress,
            gasLimit: Web3.utils.toHex(600000),
            to: contractAddress,
        }
        return window.web3.eth.sendTransaction(rawTransaction, (err, data) => callback(err, data))
    }

    static isInWhitelist(projectId, walletAddress) {
        return http.get('/whitelists/in-whitelist', {
            params: {
                projectId,
                walletAddress,
            },
        })
    }

    static signWallet(publicAddress, chainId) {
        return http.post('/SignWallets/sign-wallet', {
            publicAddress,
            chainId,
        })
    }

    static transferLockToken({
        accountAddress,
        lockContractAddress,
        contractAbi,
        gasPrice,
        depositId,
        receiveAccountAddress,
        callback,
    }) {
        const contract = new window.web3.eth.Contract(contractAbi, lockContractAddress)

        const extendData = contract.methods.transferLocks(depositId, receiveAccountAddress).encodeABI()

        const rawTransaction = {
            value: 0,
            gasPrice,
            data: extendData,
            from: accountAddress,
            gasLimit: window.web3.utils.toHex(600000),
            to: lockContractAddress,
        }
        return window.web3.eth.sendTransaction(rawTransaction, (err, data) => callback(err, data))
    }

    static extendToken({
        accountAddress,
        lockContractAddress,
        contractAbi,
        gasPrice,
        depositId,
        newUnlockTime,
        callback,
    }) {
        const contract = new window.web3.eth.Contract(contractAbi, lockContractAddress)

        const extendData = contract.methods.extendLockDuration(depositId, newUnlockTime).encodeABI()

        const rawTransaction = {
            value: 0,
            gasPrice,
            data: extendData,
            from: accountAddress,
            gasLimit: window.web3.utils.toHex(600000),
            to: lockContractAddress,
        }
        return window.web3.eth.sendTransaction(rawTransaction, (err, data) => callback(err, data))
    }
}

/* eslint-disable quotes */
import { createAction, createActions } from 'redux-actions'
import Api from '../../../services/api'
import { cacheApi } from '../../../utils/common'

export const setCreateFormLoading = createAction('SET_CREATE_FORM_LOADING')

export const getContractCatch = (projectAbi, address) => {
    try {
        return new window.web3.eth.Contract(projectAbi, address)
    } catch (ex) {
        // console.error(ex, 'getContract')
        return null
    }
}

const { fetchUsdRequest, fetchUsdSuccess, fetchUsdFail } = createActions({
    FETCH_USD_REQUEST: () => { },
    FETCH_USD_SUCCESS: data => ({ data }),
    FETCH_USD_FAIL: error => ({ error }),
})

export const fetchUsd = () => dispatch => {
    dispatch(fetchUsdRequest())
    return cacheApi
        .call('fetchUsd', Api.Projects.fetchUsd)
        .then(({ data }) => {
            dispatch(fetchUsdSuccess(data))
            return data
        })
        .catch(error => {
            dispatch(fetchUsdFail(error))
        })
}


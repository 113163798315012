import web3 from 'web3';
import { CHAIN_MAINNET } from './constants';

export default function formatNumber(value, prefix = '', fixed_amount = 2, thousands_separator = ',') {
    const newValue = normalizeValue(value);

    if (isNaN(value)) {
        return 'NaN';
    }

    return prefix + separateThousands(newValue.toFixed(fixed_amount), thousands_separator)
}

function normalizeValue(value) {
    if (typeof value === 'string') {
        return parseFloat(value)
    }

    return value;
}

function separateThousands(x, s) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, s);
}

export function formatBlockchainAddress(address, precision = 4, precisionEnd) {
    if (!address) {
        return ''
    }

    return `${address.slice(0, precision + 2)}...${address.slice(precisionEnd ? -precisionEnd : -precision)}`
}


export function formatAmount(value) {
    if(value > 0) {
        const formatEth = (+web3.utils.fromWei(
            String(value ? value : 0), 'ether'
        )).toFixed(3);
    
        if (formatEth.slice(formatEth.length - 3) === '000') {
            return `~${formatEth}`
        }
        return formatEth ?? 0;
    }
}

export function contractAddresses(chainId) {
    if (chainId === CHAIN_MAINNET) {
        return process.env.REACT_APP_LOCK_CONTRACT_BSC_TESTNET;
    }
    return process.env.REACT_APP_LOCK_CONTRACT_BSC_TESTNET;
}
import UserApi from './user';
import GameApi from './game';
import Projects from './projects'

const API = {
    Projects,
    UserApi,
    GameApi,
}

export default API;
import React, { useEffect, useState } from 'react';
import Wallet from '../../components/Wallet';
import { HEADER_NAVIGATION_ITEMS } from './constants';
import { Navbar, Nav, Button, Image } from 'react-bootstrap';
import './styles.scss';
import isEmpty from 'lodash/isEmpty';

import './styles.scss';

const _ = { isEmpty };

export default function Header(props) {
    const { onConnectClick, isConnected, address, chainId, balance, symbol } = props;
 
    const [responsiveMenu, setresponsiveMenu] = useState('');
    const [, setWindowDimensions] = useState(getWindowDimensions());

    function getWindowDimensions() {
        const { innerWidth: width } = window;
        return { width };
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleResponsiveMenu = () => {
        let cls = _.isEmpty(responsiveMenu) ? 'responsive-nav-container' : '';
        setresponsiveMenu(cls);
    };

    return (
        <div className="game-background-image home-section">
            <div className="container">
                <section className="top-section home-header-section">
                    <div className="top-section-container">
                        <div className="row">
                            <Navbar variant="dark" sticky="top">
                                <div className="col-6 col-lg-3 col-xl-2 header-logo">
                                    <Navbar.Brand>
                                        <Image
                                            src="/assets/images/logo.svg"
                                            height="40px"
                                            width="40px"
                                        />
                                    </Navbar.Brand>
                                </div>
                                <div
                                    className={`col-6 col-lg-6 col-xl-8 text-end text-lg-center mobile-menu-section ${responsiveMenu}`}
                                >
                                    {_.isEmpty(responsiveMenu) ? (
                                        <Button
                                            type="link"
                                            className="mobile-icon mobile-menu-bar"
                                            // style={{display:'none'}}
                                            onClick={() =>
                                                handleResponsiveMenu()
                                            }
                                        >
                                            <Image
                                                src="/assets/images/mobile-menu-bar.png"
                                                className=""
                                            />
                                        </Button>
                                    ) : (
                                        <Button
                                            type="link"
                                            className="mobile-icon mobile-menu-close"
                                            // style={{display:'none'}}
                                            onClick={() =>
                                                handleResponsiveMenu()
                                            }
                                        >
                                            <Image
                                                src="/assets/images/close.png"
                                                className=""
                                            />
                                        </Button>
                                    )}
                                    <Nav className="menu mobile-responsive-menu">
                                        {HEADER_NAVIGATION_ITEMS.map(
                                            (item, index) => (
                                                <Nav.Link
                                                    key={index}
                                                    className={
                                                        window.location
                                                            .pathname ===
                                                        item.path
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href={item.path}
                                                >
                                                    {item.name}
                                                </Nav.Link>
                                            )
                                        )}
                                        <Nav className="header-join-chat-btn-navbar">
                                            <div className="header-join-chat-btn">
                                                {isConnected ? (
                                                    <Wallet
                                                        {...{
                                                            address,
                                                            chainId,
                                                            balance,
                                                            symbol,
                                                        }}
                                                    />
                                                ) : (
                                                    <>
                                                        <Button
                                                            variant="warning"
                                                            onClick={
                                                                onConnectClick
                                                            }
                                                        >
                                                            <span className="header-join-chat-text">
                                                                Connect
                                                            </span>
                                                        </Button>
                                                    </>
                                                )}
                                            </div>
                                        </Nav>
                                    </Nav>
                                </div>
                                <div className="col-6 col-lg-3 col-xl-2 text-end desktop-join-chat">
                                    <Nav>
                                        <div className="header-join-chat-btn">
                                            {isConnected ? (
                                                <Wallet
                                                    {...{
                                                        address,
                                                        chainId,
                                                        balance,
                                                        symbol,
                                                    }}
                                                />
                                            ) : (
                                                <>
                                                    <Button
                                                        variant="warning"
                                                        onClick={onConnectClick}
                                                    >
                                                        <span className="header-join-chat-text">
                                                            Connect
                                                        </span>
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    </Nav>
                                </div>
                            </Navbar>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

/* eslint-disable */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { contractAddresses } from '../../utils';
import PresenterABI from '../../abi/devilflip_presenter.abi.json';
import {
    fetchCanSpin,
    fetchIsSpin,
    fetchSpinVRF,
    fetchCycleIndex,
    fetchCyclePresenter,
    fetchAngelBank,
    fetchDevilBank
} from '../../utils/callHelpers';

import { fetchPrevCycleInfo } from '../../store/modules/Game/actions';
import { SECONDS_DURATION_AFTER_HAS_RESULT, SPIN_DIRECTION } from './constants';

export function UseGameHooks(props) {
    const dispatch = useDispatch();

    const { account, realTime, isConnect, setDirection, direction } = props;

    const [isModal, setIsModal] = useState(false);
    const [canSpin, setCanSpin] = useState(null);
    const [isSpinning, setSpinning] = useState(false);
    const [isSpinLoading, setSpinRequest] = useState(false);
    const [isWaitingResult, setWaitingResult] = useState(false);

    const gameInfo = useSelector(state => state.game ?? {});
    const isCountDown = useSelector(state => state.game?.isCountDown);

    // get contract address
    const addresses = contractAddresses(account);

    // this effect is call every one minute based on realTime variable
    useEffect(async () => {
        try {
            if (!isConnect) return;

            const presenterContract = new window.web3.eth.Contract(PresenterABI, addresses);
            
            const isSpinValid = await getCanSpin().then(val => Boolean(val)).catch(false);
            const isSpinning = Boolean(await fetchIsSpin(presenterContract));
    
            const isUserCanSpin = isSpinValid && !isSpinning; // hasCanSpin and isSpinning is false so allow user spin
            const isSystemSpinning = isSpinning; // isSpinning is true so system is spinning, it means user is not allowed spin

            setCanSpin(isUserCanSpin);
            setSpinning(isSystemSpinning);
        } catch (error) {
            return error;
        }
    }, [realTime]);

    useEffect(async () => {
        try {
            if (!isConnect) return;

            const presenterContract = new window.web3.eth.Contract(PresenterABI, addresses);
            const index = await fetchCycleIndex(presenterContract);

            const getIndex = Number(index) - 1;

            const currentCycle = await fetchCyclePresenter(presenterContract, `${getIndex}`);
            const angelBank = await fetchAngelBank(presenterContract);
            const devilBank = await fetchDevilBank(presenterContract);

            const endTime = Number(currentCycle?.endTime ?? 0) * 1000;

            if (isCountDown || !isSpinning) {
                if (
                    endTime > 0 &&
                    dayjs().valueOf() < dayjs(endTime).add(SECONDS_DURATION_AFTER_HAS_RESULT, 'second').valueOf() &&
                    [
                        SPIN_DIRECTION.BALANCE,
                        SPIN_DIRECTION.LEFT,
                        SPIN_DIRECTION.RIGHT
                    ].includes(direction)
                ) {
                    setWaitingResult(true);
                    return;
                }
                if (!isWaitingResult) {
                    setDirection(SPIN_DIRECTION.DEFAULT);
                    return;
                }
            }

            // const listHistory = await Api.GameApi.fetchHistory(
            //     index,
            //     5,
            //     Api.GameApi.fetchCyclePresenter
            // );

            dispatch(fetchPrevCycleInfo(
                {
                    angelBank,
                    devilBank,
                    // listHistory,
                    index: `${getIndex}`, 
                    currentCycle
                }
            ));
        } catch (error) {
            return error
        }
    }, [realTime, isCountDown, isSpinning, direction, isWaitingResult])

    /*
        Get canSpin()
    */
    const getCanSpin = async () => {
        try {
            const presenterContract = new window.web3.eth.Contract(PresenterABI, addresses);
            const hasSpin = await fetchCanSpin(presenterContract);
    
            if (hasSpin) return true;
    
            return false;
        } catch {
            return false;
        }
    };

    const handleOnSpin = async () => {
        try {
            const presenterContract = new window.web3.eth.Contract(PresenterABI, addresses);

            await setSpinRequest(true);

            fetchSpinVRF(presenterContract, account)
                .then(() => {
                    setSpinRequest(false);
                })
                .catch(err => {
                    setSpinRequest(false);
                    return err;
                })
        } catch (error) {
            setSpinRequest(false);
            return error;
        }
    }

    const openDialog = async () => {
        if (gameInfo) {
            // const featureTime = gameInfo.cycle?.startTime + gameInfo.cycle?.duration;

            // if (featureTime <= Math.round(Date.now / 1000)) {
            //     const listHistory = await Api.GameApi.fetchHistory(
            //         gameInfo.index,
            //         5,
            //         Api.GameApi.fetchCyclePresenter
            //     );
            // }
            setIsModal(true);
        }
    };

    const getTimeCountDown = () => {
        if (gameInfo && isCountDown) {
            const now = dayjs();
            const startTime = dayjs(gameInfo.cycle?.startTime * 1000);
            const duration = Number(gameInfo.cycle?.duration);

            if (startTime && duration) {
                let diffTime = startTime && startTime.diff(now, 'second');

                const timer = duration + diffTime;
    
                const hours = Math.floor(timer / 3600) % 24;
                const minutes = Math.floor(timer / 60) % 60;
                const seconds = timer % 60;
            
                return {
                    hours: hours >= 10 ? hours : `0${hours}`,
                    minutes: minutes >= 10 ? minutes : `0${minutes}`,
                    seconds: seconds >= 10 ? seconds : `0${seconds}`,
                };
            }

            return {
                hours: '00',
                minutes: '00',
                seconds: '00',
            };
        }

        return {
            hours: '00',
            minutes: '00',
            seconds: '00',
        };
    }

    return {
        isModal,
        setIsModal,
        openDialog,
        getTimeCountDown,
        canSpin,
        isSpinning,
        handleOnSpin,
        isSpinLoading,
        isWaitingResult,
        setWaitingResult
    }
}
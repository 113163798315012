import React from 'react';
import cn from 'classnames';
import style from './Emotion.module.scss';
import { formatAmount } from '../../utils';

// price > 0 ? formatAmount(String(price)) : 0

export default function Emotion(props) {
    const { disable, active, type, price = 0 } = props;
    const isType = type === 'angel';

    const getPrice = isType ? (price > 0 ? formatAmount(String(price)) : 0) : price;

    return (
        <div
            className={cn(
                style.wrapperEmotion,
                isType ? style.wrapperEmotion2 : style.wrapperEmotion1
            )}
        >
            <div className={style.contentTitle}>
                <p
                    className={
                        isType ? style.headTitleAngel : style.headTitleDevil
                    }
                >
                    {isType ? 'Angel Pumper' : 'Devil Dumper'}
                </p>
                <div className={style.price}>
                    <p>{`${getPrice} ${
                        isType ? 'BNB' : 'DFLIP'
                    }`}</p>
                    <p>{isType ? 'Buying Power' : 'Selling Power'}</p>
                </div>
                <div
                    className={cn(
                        style.devilflip,
                        isType ? style.devil1 : style.devil2
                    )}
                >
                    {disable ? (
                        <img className={style.activeBtn} src={active} alt='' />
                    ) : null}
                </div>
            </div>
        </div>
    );
}


// import Logger from 'js-logger';
// import { get } from 'lodash';

export const CONTRACT_TIMEOUT = 5;
export const ERROR_CODE_TIME_OUT = 408;
export const MESSAGE_TIMEOUT_MINT = 'There was a timeout while attempting to mint your transaction.'

export const fetchTokenAddress = async (presenterContract) => {
    return presenterContract.methods.token().call();
}

export const fetchCycleIndex = async (presentContract) => {
    return presentContract.methods.cycleIndex().call();
}

export const fetchCyclePresenter = async (presentContract, cycleIndex) => {
    return presentContract.methods.cycles(cycleIndex).call();
}

export const fetchCanSpin = async (presentContract) => {
    return presentContract.methods.canSpin().call();
}

export const fetchDevilBank = async (presentContract) => {
    return presentContract.methods.devilBank().call();
}

export const fetchAngelBank = async (presentContract) => {
    return presentContract.methods.angelBank().call();
}

export const fetchSpinVRF = async (presentContract, senderAddress) => {
    return presentContract.methods.spinVRF()
        .send({ from: senderAddress })
        .on('transactionHash', (tx) => {
            return tx.transactionHash;
        });
}

export const fetchIsSpin = async (presentContract) => {
    return presentContract.methods.isSpinning().call();
}
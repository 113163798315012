import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { withAppContext } from './context';
import { ModalProvider } from '@pancakeswap-libs/uikit';
import { RefreshContextProvider } from './context/RefreshContext';

// import createStore from './configureStore'
import 'antd/dist/antd.compact.min.css';
import './styles/index.scss';
import App from './App';
import store from './store';

// const { store } = createStore();

const AppWithContext = withAppContext(App)

ReactDOM.render(
    <Provider store={store}>
        <RefreshContextProvider>
            <ModalProvider>
                <Router>
                    <AppWithContext />
                </Router>
            </ModalProvider>
        </RefreshContextProvider>
    </Provider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

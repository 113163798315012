import React from 'react';
import style from './Helper.module.scss';
// import cn from 'classnames';

export default function Helper(props) {
    const { text = '', css } = props;

    return (
        <div className={style.container}>
            {text ? (
                <span className={style.span} style={css}>
                    {text}
                </span>
            ) : null}
            {props.children}
        </div>
    );
}

import { handleActions } from 'redux-actions';
import { persistReducer } from 'redux-persist';
import localForage from 'localforage';
import { THEME_TYPES } from './constants';

const initialState = {
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    connectModalVisible: false,
    load: {
        requesting: false,
        loaded: false
    },
    isDarkMode: false,
    theme: THEME_TYPES.LIGHT
};

export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_IS_TABLET = 'SET_IS_TABLET';
export const SET_IS_DESKTOP = 'SET_IS_DESKTOP';
export const SET_CONNECT_MODAL_VISIBLE = 'SET_CONNECT_MODAL_VISIBLE';
export const LOADING_REQUEST = 'LOADING_REQUEST';
export const LOADING_SUCCESS = 'LOADING_SUCCESS';
export const SET_THEME = 'SET_THEME';

let appReducer = handleActions({
    [SET_THEME]: (state, { payload }) => ({
        ...state,
        theme: payload,
    }),
    [SET_CONNECT_MODAL_VISIBLE]: (state, { payload }) => ({
        ...state,
        connectModalVisible: payload,
    }),
    [SET_IS_MOBILE]: (state) => ({
        ...state,
        isMobile: true,
        isTablet: false,
        isDesktop: false
    }),
    [SET_IS_TABLET]: (state) => ({
        ...state,
        isMobile: false,
        isTablet: true,
        isDesktop: false
    }),
    [SET_IS_DESKTOP]: (state) => ({
        ...state,
        isMobile: false,
        isTablet: false,
        isDesktop: true
    }),
    [LOADING_REQUEST]: (state) => ({
        ...state,
        load: {
            ...state.load,
            requesting: true
        }
    }),
    [LOADING_SUCCESS]: (state) => ({
        ...state,
        load: {
            ...state.load,
            requesting: false,
            loaded: true
        }
    }),
}, initialState);

appReducer = persistReducer({
    key: 'app',
    storage: localForage,
    whitelist: ['isDarkMode', 'isAutoplay']
}, appReducer);

export default appReducer;

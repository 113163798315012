export const FAKE_TRANSACTION_LIST = [
    {
        name: 'Minting Tokens to Network',
        createdAt: '2021-05-02',
    },
    {
        name: 'Lock 3,000,000 SWAP Tokens',
        createdAt: '2021-05-02',
    },
]

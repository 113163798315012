import React from 'react';
import './style.scss';
import { Image } from 'react-bootstrap';

const Mobile_Friendly = () => {
    return (
        <div className="mobile-friendly-gradient-background">
            <section className='mobile-friendly mobile-friendly-section'>
                <div className="mobile-friendly-container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-2 order-lg-1 mt-0 mt-md-0 pt-4 pt-md-5 custom-col-2">
                            <Image
                                src="/assets/images/mobile_friendly.png"
                                className="mobile-friendly-phone-img pe-sm-3"
                            />
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 text-center text-lg-start custom-col-1">
                            <div className="mobile-friendly-text-part">
                                <h3 className="devilflip-h3-heading mb-2 mb-md-3">Mobile Friendly Gameplay for All Devices.</h3>
                                <p className="mobile-friendly-devilflip-paragraph mb-3 mb-md-4 pb-md-1">
                  The Memewars web app is available on both desktop and mobile
                  devices,optimized for all screen sizes and compatible with the
                  leading crypto wallets like Trust-Wallet ,Metamask,Coinbase
                  Wallet and more.
                                </p>
                                <div className="mobile-friendly-icons">
                                    <Image src='/assets/images/mobile_friendly_logo1.png' height="30px" width="30px" />
                                    <Image src='/assets/images/mobile_friendly_logo2.png' height="30px" width="30px" />
                                    <Image src='/assets/images/mobile_friendly_logo3.png' height="30px" width="30px" />
                                    <Image src='/assets/images/mobile_friendly_logo4.png' height="30px" width="30px" />
                                    <Image src='/assets/images/mobile_friendly_logo5.png' height="30px" width="30px" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Mobile_Friendly;

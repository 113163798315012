import { find, floor, get, map, parseInt, random, times } from 'lodash'
import moment from 'moment'
import Web3 from 'web3'

function toPlainString(num) {
    return ('' + num).replace(/(-?)(\d*)\.?(\d+)e([+-]\d+)/, function (a, b, c, d, e) {
        return e < 0 ? b + '0.' + Array(1 - e - c.length).join(0) + c + d : b + c + d + Array(e - d.length + 1).join(0)
    })
}

export const shortAddress = address => {
    if (address.length < 10) return address
    const one = address.slice(0, 6)
    const two = address.slice(address.length - 5, address.length)
    return [one, two].join('...')
}

export const isAVAX = chainId => ['0xa86a', '0xa869'].includes(chainId)

export const contractAddress = chainId => {
    const address = {
        '0x1': Web3.utils.toChecksumAddress('0xc77aab3c6d7dab46248f3cc3033c856171878bd5'),
        '0x3': Web3.utils.toChecksumAddress('0x8733caa60eda1597336c0337efde27c1335f7530'),
        // BSC Mainntet not available yet
        '0x38': Web3.utils.toChecksumAddress('0x7536592bb74b5d62eb82e8b93b17eed4eed9a85c'),
        '0x61': Web3.utils.toChecksumAddress('0x8007d9ed1328027bc107c432e4ef554b0ceb4de8'),
        '0xa86a': Web3.utils.toChecksumAddress('0x88aDa02f6fCE2F1A833cd9B4999D62a7ebb70367'),
        '0xa869': Web3.utils.toChecksumAddress('0x91Ddb0842e40157BFa445e8020DA2A584098B780'),
    }
    return address[chainId] || address['0x1']
}

export const lockTokenContractAddress = chainId => {
    const address = {
        '0x1': contractAddress('0x1'),
        '0x3': contractAddress('0x3'),
        '0x38': contractAddress('0x38'),
        '0x61': contractAddress('0x61'),
        '0xa86a': contractAddress('0xa86a'),
        '0xa869': contractAddress('0xa869'),
    }
    return address[chainId] || address['0x1']
}

export const defaultFormatDate = 'MM/DD/YYYY'
const MEME_FEE_RATE = +process.env.REACT_APP_MEME_FEE_RATE || 0.05

export const oldContactAddress = {
    '0x1': '0xdbf72370021babafbceb05ab10f99ad275c6220a',
    '0x3': '0x7f207d66240fbe8db3f764f6056b6be8725cc90a',
}

export const convertTokenAmount = ({ decimals, value = 0, toFixed = 1, noFixed = false }) => {
    let denominator = '1'
    for (let i = 0; i < decimals; i++) {
        denominator += '0'
    }

    if (noFixed) {
        return value / Number(denominator)
    }

    return fixedFloat(toPlainString(value / Number(denominator)), toFixed)
}

const fixedFloat = (input, decimals) => {
    const arr = ('' + input).split('.')
    if (arr.length === 1) return decimals === 0 ? input : [input, '.', times(decimals, () => '0').join('')].join('')
    const int = arr[0]
    const max = arr[1].length
    const dec = arr[1].substr(0, decimals > max ? max : decimals)
    return decimals === 0
        ? int
        : [int, '.', dec.length >= decimals ? dec : dec + times(decimals - dec.length, () => '0').join('')].join('')
}

export const getTotalForSaleNoFee = totalForSale => {
    return +(totalForSale - MEME_FEE_RATE * totalForSale).toFixed(0)
    // return totalForSale
}

export const getView = width => {
    let newView = 'MobileView'
    if (width > 1220) {
        newView = 'DesktopView'
    } else if (width > 767) {
        newView = 'TabView'
    }
    return newView
}

export const getFileFromType = (data, type) => {
    return find(data, { type })
}

export const convertVestingToString = ({ value, type, duration }, symbol = '') => {
    // {value: 1000, type: 'weekly', duration: 1}
    if (type == 'weekly') {
        return [`Weekly for ${duration} weeks`, `${formatNumber((value / duration).toFixed(0), '', 0)} ${symbol}/week`]
    }
    if (type == 'monthly') {
        return [`Monthly for ${duration} months`, `${formatNumber((value / duration).toFixed(0), '', 0)} ${symbol}/month`]
    }

    return [`${duration} ${type}`, `${formatNumber((value / duration).toFixed(0), '', 0)} ${symbol}`]
}
export const convertDurationToMinuteString = value => {
    const duration = moment.duration(parseInt(value), 'seconds')
    const hours = duration.hours()
    const minutes = duration.minutes()
    const seconds = duration.seconds()
    if (hours > 0) {
        return `${hours}h ${minutes}min ${seconds}sec`
    }
    if (minutes > 0) {
        return `${minutes}min ${seconds}sec`
    }
    return `${seconds}sec`
}

export const convertDurationToMinute = (value, notShowMinute) => {
    const minute = floor(value / 60, 0)
    const seconds = floor(value % 60, 0)
    if (notShowMinute) {
        return `${minute < 10 ? `${minute}` : minute}`
    }
    // return `${minute < 10 ? `0${minute}` : minute}:${seconds < 10 ? `0${seconds}` : seconds}`;
    return `${minute < 10 ? `${minute}` : minute}:${seconds < 10 ? `0${seconds}` : seconds}`
}

export const createDefaultUploadFile = (data, isSoundFile) => {
    let name = get(data, 'name', '')
    let url = get(data, 'url', '')
    const id = get(data, 'id', '')
    if (!isSoundFile) {
        const imageData = getFileFromType(data, 'medium', '')
        name = get(imageData, 'name', '')
        url = get(imageData, 'url', '')
    }
    // uid must be string number
    return [
        {
            uid: `${moment().valueOf()}`,
            id,
            name,
            url,
            status: 'done',
        },
    ]
}

export const createDefaultFileImage = data => {
    const id = get(data, 'id')
    const name = get(data, 'data.0.name')
    const url = get(data, 'data.0.url')

    if (!id) {
        return null
    }
    return [{ uid: id, name, url, status: 'done', id }]
}

export const createDefaultFileMp4 = data => {
    const id = get(data, 'id')
    const name = get(data, 'data.0.name')
    const url = get(data, 'data.0.url')

    if (!id) {
        return null
    }

    // uid must be string number
    return [{ uid: id, name, url, status: 'done', id }]
}

export const getDurationAudio = file => {
    return new Promise(resolve => {
        const objectURL = URL.createObjectURL(file)
        const myAudio = new Audio([objectURL])
        myAudio.addEventListener(
            'canplaythrough',
            () => {
                URL.revokeObjectURL(objectURL)
                resolve({
                    file,
                    duration: myAudio.duration,
                })
            },
            false,
        )
    })
}

export const upperCaseFirstLetter = text => {
    return `${text.charAt(0).toUpperCase()}${text.slice(1)}`
}

export const getQueryParam = (name, defaultData = '') => {
    const q = window.location.search.match(new RegExp('[?&]' + name + '=([^&#]*)'))
    return q ? q[1] : defaultData
}

export const isImage = url => /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/.test(url)

export const widthModal = width => {
    if (width < 768) {
        return width
    }
    if (width > 767 && width < 992) {
        return width / 2 + 100
    }
    if (width > 991 && width < 1025) {
        return width / 2
    }
    if (width > 1024 && width < 1220) {
        return width / 2
    }
    return width / 3
}

export const getAvatarBg = () => {
    const number = random(1, 8)
    return {
        blue: `${process.env.PUBLIC_URL}/images/avatar-bg/${number}-blue.png`,
        gray: `${process.env.PUBLIC_URL}/images/avatar-bg/${number}-gray.png`,
    }
}

export const getHeightOffsetTopAnimations = () => {
    const height = document.getElementsByClassName('active-animation')
    const listHeightAnimation = map(height, ele => {
        const heightTemp = get(ele, 'offsetTop', 0)
        return heightTemp
    })
    return listHeightAnimation
}

export const formatCurrency = (currency, fixed = 2) => {
    if (!currency) {
        return ''
    }
    if (typeof currency === 'number') {
        return currency.toFixed(fixed).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
    }
    return (
        currency
            // eslint-disable-next-line
            .replace(/[(a-zA-Z)\s\_\,\-]+/g, '')
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
    )
}

export const formatNumber = (value, prefix = '', fixed_amount = 2, thousands_separator = ',') => {
    const newValue = normalizeValue(value)

    if (isNaN(value)) {
        return 'NaN'
    }

    return prefix + separateThousands(newValue.toFixed(fixed_amount), thousands_separator)
}

// coinChainId
// = 0: Ethereum
// = 1: Bsc
// = 2: Avax
export const getCoinChainId = chainId => {
    if (['0x1', '0x3'].includes(chainId)) {
        return 0
    } else if (['0x38', '0x61'].includes(chainId)) {
        return 1
    } else if (['0xa86a', '0xa869'].includes(chainId)) {
        return 2
    } else {
        return -1
    }
}

export const getTimeLeft = (start, end) => {
    const dateLocked = moment.unix(start)
    const unlockDate = moment.unix(end)
    const duration = moment.duration(unlockDate.diff(moment()))
    const years = duration.get('y')
    const months = duration.get('M')
    const days = duration.get('d')
    const hours = duration.get('h')
    const minutes = duration.get('m')
    const seconds = duration.get('s')

    const yearsString = `${years} ${years > 1 ? 'Years' : 'Year'}`
    const monthsString = `${months} ${months > 1 ? 'Months' : 'Month'}`
    const daysString = `${days} ${days > 1 ? 'Days' : 'Day'}`
    const hoursString = `${hours} ${hours > 1 ? 'Hours' : 'Hour'}`
    const minutuesString = `${minutes} ${minutes > 1 ? 'Minutes' : 'Minute'}`
    const secondsString = `${seconds} ${seconds > 1 ? 'Seconds' : 'Second'}`

    const response = {
        timeLeft: 0,
        timeLeftString: 'No Locks',
        format: 60,
        type: 'n',
        percent: 0,
    }

    if (years) {
        response.timeLeft = years
        response.timeLeftString = yearsString
        response.format = 10
        response.type = 'y'
    } else if (months) {
        response.timeLeft = months
        response.timeLeftString = monthsString
        response.format = 12
        response.type = 'M'
    } else if (days) {
        response.timeLeft = days
        response.timeLeftString = daysString
        response.format = 30
        response.type = 'd'
    } else if (hours) {
        response.timeLeft = hours
        response.timeLeftString = hoursString
        response.format = 24
        response.type = 'h'
    } else if (minutes) {
        response.timeLeft = minutes
        response.timeLeftString = minutuesString
        response.format = 60
        response.type = 'm'
    } else if (seconds) {
        response.timeLeft = seconds
        response.timeLeftString = secondsString
        response.format = 60
        response.type = 'm'
    }
    response.percentLeft = (unlockDate.diff(moment()) / unlockDate.diff(dateLocked)) * 100
    return response
}

function normalizeValue(value) {
    if (typeof value === 'string') {
        return parseFloat(value)
    }

    return value
}

export function separateThousands(x, s) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, s)
}

export function formatBlockchainAddress(address, precision = 4, precisionEnd) {
    if (!address) {
        return ''
    }

    return `${address.slice(0, precision + 2)}...${address.slice(precisionEnd ? -precisionEnd : -precision)}`
}

export const haveContract = chainId => ['0x1', '0x3', '0x38', '0x61'].includes(chainId)

export const getExplorerUrl = chainId => {
    const urls = {
        '0x1': process.env.REACT_APP_BLOCK_EXPLORER_URL_ETH_MAINNET,
        '0x3': process.env.REACT_APP_BLOCK_EXPLORER_URL_ETH_ROPSTEN,
        '0x38': process.env.REACT_APP_BLOCK_EXPLORER_URL_BSC_MAINNET,
        '0x61': process.env.REACT_APP_BLOCK_EXPLORER_URL_BSC_TESTNET,
    }
    if (haveContract(chainId)) {
        return urls[chainId]
    }
    return urls['0x3']
}

export const uniswapApiUri = chainId => {
    const address = {
        '0x1': 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2',
        '0x3': 'https://api.thegraph.com/subgraphs/name/pollendefi/uniswap-v2-ropsten',
        '0x38': 'https://api.thegraph.com/subgraphs/name/hungpq14/subgraph-temp',
        '0x61': 'https://api.thegraph.com/subgraphs/name/hungpq14/subgraph-temp',
        '0xa86a': 'https://cchain.explorer.avax.network/graphiql',
        '0xa869': 'https://cchain.explorer.avax-test.network/graphiql',
    }
    return address[chainId] || address['0x1']
}

// reduce over hit
export const cacheApi = {
    api: {},
    call: (key, apiFunction, timeout = 500) => {
        if (cacheApi.api[key] && new Date().getTime() - cacheApi.api[key].start <= timeout) {
            // trong thoi gian cache
            // console.info('cache ignore', key)
            return cacheApi.api[key].api
        }
        cacheApi.api[key] = {
            start: new Date().getTime(),
            timeout,
            api: apiFunction(),
        }

        return cacheApi.api[key].api
    },
}

export const getPublicSale = launchDate => {
    return moment(launchDate)
        .add(process.env.REACT_APP_PUBLIC_SALE_DURATION || 24 * 60, 'minutes')
        .unix() // 10p for testing, real 1day
}

export const mappingFakeToken = {
    // fake for external api
    '0x1592e4A1ca77Ae665fb2c3635f1Ce5B5c6acB1E2': '0x9d70a3ee3079a6fa2bb16591414678b7ad91f0b5', // fake memepad
    '0x198c7486348dE3Cfc04b08544133AE818FEd0bC9': '0xba2ae424d960c26247dd6c32edc70b295c744c43', // fale
}

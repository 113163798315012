import React from 'react';
import HomeComponent from '../../components/Home/index';
import Layout from '../../layouts/PageLayout';

export default function Home() {
    return (
        <Layout>
            <HomeComponent />
        </Layout>
    );
}

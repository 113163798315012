import React from 'react';
import { Button } from 'react-bootstrap';
import style from './ListHistory.module.scss';
import cn from 'classnames';
import { formatAmount } from '../../utils'

export default function ListHistory(props) {
    const { listHistory = [] } = props;

    const HistoryElement = ({ item, index }) => {
        const time = new Date(item.endTime * 1000);
        const isHero = Number(item.winner) === 1;
        const spacingTop = index > 0 ? style.pt : null;
        const paragraph = isHero ? style.paragraph1 : style.paragraph2;
        const timeFormat = `${
            time.getUTCMonth() + 1
        }-${time.getUTCDate()}-${String(time.getUTCFullYear()).slice(2)}`;

        return (
            <>
                <div className={cn(style.historyItem, style.pb, spacingTop)}>
                    <div className={cn(style.devilflip, isHero ?style.devil1 : style.devil2 )}>
                        <Button variant="outline-info">
                            <span>{timeFormat}</span>
                        </Button>
                    </div>
                    <div className={style.text}>
                        <p className={cn(style.paragraph, paragraph)}>
                            {isHero
                                ? 'Angel Pumped'
                                : 'Devil Dumped '}
                        </p>
                    </div>
                    <div className={cn(style.text, style.textEnd)}>
                        <p
                            className={cn(
                                style.paragraph,
                                paragraph,
                                style.price
                            )}
                        >
                            {`${
                                Number(item.amount) > 0
                                    ?  formatAmount(item.amount)
                                    : 0
                            } ${ isHero? 'BNB' : 'DFLIP'}`}
                        </p>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className={style.wrapperHistory}>
            {listHistory && listHistory.map((element, index) => (
                <HistoryElement key={index} index={index} item={element} />
            ))}
        </div>
    );
}

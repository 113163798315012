import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import userReducer from './modules/User/reducer';
import appReducer from './modules/App/reducer';
import wallet from './modules/Wallet/reducer';
import gameReducer from './modules/Game/reducer';

const rootReducer = combineReducers({
    app: appReducer,
    user: userReducer,
    wallet: wallet,
    router: routerReducer,
    game: gameReducer
});

export default rootReducer;
import {
    PAGE_HOME_PATH,
    PAGE_GAME_PATH,
} from '../../router/constants';

export const HEADER_NAVIGATION_ITEMS = [
    {name: 'Home', path: PAGE_HOME_PATH},
    {name: 'Game', path: PAGE_GAME_PATH},
    {name: 'Docs', path: '/Docs'},
    {name: 'Wagyu', path: '/Wagyu'},
];
import React from 'react';
import { useSelector } from 'react-redux';

import Modal from '../../Modal';
import Spin from '../../Spin';

import style from './SwitchNetworkModal.module.scss';

export default function SwitchNetworkModal(props) {
    // const chainId = useSelector((state) => state.user.chainId);
    const wrongNetwork = useSelector((state) => state.user?.wrongNetwork);
    // const [loading, setLoading] = useState(false);

    // const onChangeNetwork = async () => {
    //     setLoading(true);
    //     if (parseInt(requiredChainId) != parseInt(chainId)) {
    //         await switchNetwork(requiredChainId);
    //         setLoading(false);
    //     }
    // };

    return (
        <Modal
            {...props}
            visible={wrongNetwork}
            closable={false}
            childrenClass={style.wrapper}
            centered
        >
            <h3 className={style.heading}>Wrong network</h3>
            <h3 className={style.text}>Change network to Binance Smart Chain</h3>
            <div className={style.logout}>
                {/* <Button
                    text={'Change Network'}
                    onClick={() => onChangeNetwork()}
                    primary
                    blue
                    wrapperClass={style.button}
                /> */}
            </div>
            {wrongNetwork ? <Spin wrapperClass={style.spinner} /> : ''}
        </Modal>
    );
}

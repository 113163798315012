import { createAction, createActions } from 'redux-actions';

import moment from 'moment';
import Web3 from 'web3';
import { get, filter, isEmpty } from 'lodash';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import Logger from 'js-logger';

import fromExponential from 'from-exponential';
import detectEthereumProvider from '@metamask/detect-provider';
import WalletConnectProvider from '@walletconnect/web3-provider';

import { ALLOWED_NETWORKS } from './constants';
import { setConnectModalVisible } from '../App/actions';

import Api from '../../../services/api';
import { formatUnits } from '../../constants/web3';
import { nodes, testNodes } from '../../../utils/getRpcUrl';
import { CONNECTION_TYPES } from '../../../components/ConnectToWalletModal/constants';

export const setNotifications = createAction('SET_NOTIFICATIONS');
export const setChainId = createAction('SET_NETWORK');
export const setWrongNetwork = createAction('SET_WRONG_NETWORK');
export const setUserAccounts = createAction('SET_USER_ACCOUNTS');
export const clearUserDataOnDisconnectMetamask = createAction('CLEAR_USER_DATA_ON_DISCONNECT_METAMASK');

const { connectWalletRequest, connectWalletSuccess, connectWalletFail } = createActions({
    CONNECT_WALLET_REQUEST: () => { },
    CONNECT_WALLET_SUCCESS: data => ({ data }),
    CONNECT_WALLET_FAIL: error => ({ error })
});

// export const loadNotifications = (account) => (dispatch) => {
//     Api.UserApi.fetchNotifications(account).then(result => (
//         dispatch(setNotifications(result.data))
//     ));
// }

export const setNetwork = (chainId) => (dispatch) => {
    dispatch(setChainId(chainId));
    dispatch(validateNetwork(chainId));
};

export const validateNetwork = (chainId) => (dispatch, getState) => {
    const { wrongNetwork } = getState().user;

    if (chainId) {
        const hexChainId = `${chainId.toString().slice(0,2) === '0x' ? '' : '0x'}${chainId.toString(16)}`;
        const isValid = ALLOWED_NETWORKS.indexOf(hexChainId) !== -1;

        if (!isValid) {
            dispatch(setWrongNetwork(true));
        } else if (isValid) {
            dispatch(setWrongNetwork(false));
        }
    } else if (wrongNetwork) {
        dispatch(setWrongNetwork(false));
    }
};

export const connectMetaMask = () => async dispatch => {
    dispatch(connectWalletRequest());

    // Check metamask is install or not
    if (window.ethereum) {
        const provider = await detectEthereumProvider();
        // If the provider returned by detectEthereumProvider is not the same as
        // window.ethereum, something is overwriting it, perhaps another wallet.
        if (provider !== window.ethereum) {
            window.web3 = new Web3(provider);
        } else {
            window.web3 = new Web3(window.ethereum);
        }

        window.ethereum.on('accountsChanged', async (account) => {
            await clearUserProjectData();

            try {
                if (account && account[0]) {
                    const chainId = window.ethereum.chainId;
                    const balance = await window.web3.eth.getBalance(account[0]);
    
                    dispatch(setUserAccounts({ accounts: account, balance, chainId }));
                } else {
                    dispatch(disconnect());
                }
            } catch (error) {
                return error;
            }
        })

        window.ethereum.on('chainChanged', async (chainId) => {
            try {
                window.web3 = new Web3(window.ethereum);

                const accounts = await window.web3.eth.getAccounts();
                const balance = await window.web3.eth.getBalance(accounts[0]);
    
                dispatch(setUserAccounts({ accounts: accounts, balance, chainId }));
                dispatch(setNetwork(chainId));
    
                if (ALLOWED_NETWORKS.indexOf(verifyChainId(chainId)) === -1) {
                    dispatch(setConnectModalVisible(false));
                    dispatch(setWrongNetwork(true));
                } else {
                    dispatch(setWrongNetwork(false));
                }
            } catch (error) {
                return error;
            }
        });

        // window.ethereum.on('disconnect', () => {
        //     console.log('disconnect')
        //     dispatch(disconnect());
        // });

        return window.ethereum.request({ method: 'eth_requestAccounts' })
            .then(async () => {
                dispatch(connectWalletSuccess());

                const chainId = window.ethereum.chainId;
                const accounts = await window.web3.eth.getAccounts();
                const balance = await window.web3.eth.getBalance(accounts[0]);
                dispatch(setUserAccounts({ accounts, balance, chainId }));

                // Load notifications
                // dispatch(loadNotifications(accounts[0]));

                return true;
            })
            .catch((error) => {
                dispatch(connectWalletFail(error));
                return false;
            });
    }

    return new Promise((resolve, reject) => {
        const err = 'Metamask not install.';
        dispatch(connectWalletFail(err));

        reject(err);
    });
};

export const disconnect = () => async (dispatch) => {
    dispatch(clearUserDataOnDisconnectMetamask())
    dispatch(setUserAccounts({ accounts: [] }))
    dispatch(clearUserProjectData())
    dispatch(setWrongNetwork(false));
    await window.web3?.provider?.disconnect();
    window.web3 = undefined
}

/** CLEAR TRANSACTION LOGS **/
export const clearTransactionLogs = createAction('CLEAR_TRANSACTION_LOGS');
export const clearTransactionLog = (accountAddress, chainId) => (dispatch, getState) => {
    const transactionLogs = getState().user.transactionLogs;
    const logData = transactionLogs.result;

    const filterLog = filter(logData, item => {
        return item.chainId !== chainId && get(item, 'accountAddress', '').toLowerCase() !== accountAddress.toLowerCase();
    });

    dispatch(clearTransactionLogs(filterLog));
};

export const autoConnect = () => async dispatch => {

    if (window.ethereum) {
        Logger.info('WALLET_AUTO_CONNECT');

        const provider = await detectEthereumProvider();
        // If the provider returned by detectEthereumProvider is not the same as
        // window.ethereum, something is overwriting it, perhaps another wallet.
        if (provider !== window.ethereum) {
            window.web3 = new Web3(provider);
        } else {
            window.web3 = new Web3(window.ethereum);
        }

        window.ethereum.request({ method: 'eth_chainId' }).then((chainId) => {
            Logger.info('WALLET_CHAIN_ID', chainId);

            // Set network when first time enter page
            dispatch(setNetwork(chainId));
        });

        window.ethereum.request({ method: 'eth_accounts' }).then((accounts) => {
            Logger.info('WALLET_ACCOUNTS', accounts);

            if (isEmpty(accounts)) {
                dispatch(setUserAccounts({ accounts: [], balance: 0, ris3Balance: 0 }));
            } else {
                dispatch(setUserAccounts({ accounts }));

                // Load notifications
                // dispatch(loadNotifications(accounts[0]));

                window.web3.eth.getBalance(accounts[0])
                    .then(balance => dispatch(setUserAccounts({ balance })));
            }
        });
    }
};

/** SET USER ACCOUNTS **/

export const storeTransactionLog = createAction('STORE_TRANSACTION_LOG')

export const setTransferLockTokenStep = createAction('SET_TRANSFER_LOCK_TOKEN_STEP')


export const switchNetwork = async poolChainId => {
    if (window.ethereum) {
        const chainId = parseInt(poolChainId)
        try {
            // eslint-disable-next-line no-unused-vars
            const rs = await window.ethereum
                .request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: `0x${chainId.toString(16)}`,
                            chainName: 'Binance Smart Chain Mainnet',
                            nativeCurrency: {
                                name: 'BNB',
                                symbol: 'bnb',
                                decimals: 18,
                            },
                            rpcUrls: chainId === 56 ? nodes : testNodes,
                            blockExplorerUrls: [chainId === 56 ? 'https://bscscan.com/' : 'https://testnet.bscscan.com/'],
                        },
                    ],
                })
                // eslint-disable-next-line no-unused-vars
                .catch(error => {
                    return false
                })
            return chainId === (await window.web3.eth.net.getId())
        } catch (e) {
            return false
        }
    }
    return false
}

export const verifyNetwork = async () => {
    // fixed bsc
    const chainId = process.env.REACT_APP_ALLOW_NETWORK || '0x38'
    const currentChainId = await window.web3.eth.net.getId()
    if (currentChainId != parseInt(chainId)) {
        return await switchNetwork(chainId)
    }

    return true
}

export const detectAccountLock = () => async dispatch => {
    const connectorId = window.localStorage.getItem('connectorId')
    if (connectorId === CONNECTION_TYPES.metamask) {
        if (window.ethereum) {
            const provider = await detectEthereumProvider()
            // If the provider returned by detectEthereumProvider is not the same as
            // window.ethereum, something is overwriting it, perhaps another wallet.
            if (provider !== window.ethereum) {
                window.web3 = new Web3(provider)
            } else {
                window.web3 = new Web3(window.ethereum)
            }

            const accounts = await window.web3.eth.getAccounts()
            // eslint-disable-next-line no-empty
            if (accounts && accounts[0]) {
            } else {
                dispatch(connectWalletFail())
            }
        }
    }
}

/** WITHDRAW STAKE **/
const { buyTokenRequest, buyTokenSuccess, buyTokenFail } = createActions({
    BUY_TOKEN_REQUEST: () => { },
    BUY_TOKEN_SUCCESS: data => ({ data }),
    BUY_TOKEN_FAIL: error => ({ error }),
})

export const buyToken =
    (
        contractAddress,
        accountAddress,
        projectId,
        amount,
        maxPayableAmount,
        acceptedTokenDecimals,
        toastSuccess,
        toastError,
    ) =>
        async (dispatch, getState) => {
            dispatch(buyTokenRequest())
            const { user } = getState()
            const { chainId } = user
            if (window.web3) {
                const convertAmountForTx = fromExponential(amount)
                // console.log('convertAmountForTx::',convertAmountForTx);
                // console.log('maxPayableAmount::',maxPayableAmount);
                // console.log('maxPayableAmount > convertAmountForTx::', maxPayableAmount > convertAmountForTx);
                const parseUnit = ethers.utils.parseUnits(convertAmountForTx, acceptedTokenDecimals)
                const log = {
                    convertAmountForTx,
                    chainId,
                    contractAddress,
                    accountAddress,
                    date: moment().valueOf(),
                }
                // const callback = (err, pendingTransactionHash) => {
                //   if (err) {
                //     console.info(err)
                //   }
                //   if (pendingTransactionHash) {
                //     log.transactionHash = pendingTransactionHash
                //   }
                // }
                const acceptedAmount = window.web3.utils.toHex(String(parseUnit))
                // console.info(acceptedAmount)
                return Api.Projects.buyToken({
                    projectId,
                    accountAddress,
                    contractAddr: contractAddress,
                    amount: acceptedAmount,
                })
                    .then(response => {
                        dispatch(buyTokenSuccess(response))
                        log.isError = false
                        // dispatch(storeTransactionLog(log));
                        dispatch(fetchWhitelistFromContract(projectId, accountAddress, contractAddress))
                        // dispatch(fetchProjectDetailNotReload(projectId));
                        toastSuccess('Swap Successed!', `You swapped ${convertAmountForTx} successfully`)
                        return response
                    })
                    .catch(error => {
                        const errorCode = get(error, 'code')
                        if (errorCode !== 4001) {
                            log.isError = true
                            // dispatch(storeTransactionLog(log));
                        }
                        toastError('Error', 'Please try again and confirm the transaction.')
                        return dispatch(buyTokenFail(error))
                    })
            } else {
                toastError('Error', 'Wallet is not connected.')
                dispatch(buyTokenFail('Unconnected'))
            }
        }

export const setIsWhiteList = createAction('SET_IS_WHITELIST')
export const setRewardedAmount = createAction('SET_REWARDED_AMOUNT')
export const setRedeemedAmount = createAction('SET_REDEEMED_AMOUNT')
export const setRedeemed = createAction('SET_REDEEMED')
export const setAmount = createAction('SET_AMOUNT')
export const setMaxPayableAmount = createAction('SET_MAX_PAYABLE_AMOUNT')
export const setMaxPayableAmountBNB = createAction('SET_MAX_PAYABLE_AMOUNT_BNB')
export const setMaxAllocation = createAction('SET_MAX_ALLOCATION')

const { fetchWhitelistFromContractRequest, fetchWhitelistFromContractSuccess, fetchWhitelistFromContractFail } =
    createActions({
        FETCH_WHITELIST_FROM_CONTRACT_REQUEST: () => { },
        FETCH_WHITELIST_FROM_CONTRACT_SUCCESS: data => ({ data }),
        FETCH_WHITELIST_FROM_CONTRACT_FAIL: error => ({ error }),
    })

export const fetchWhitelistFromContract = (projectId, address, smartContractAddress) => async dispatch => {
    if (smartContractAddress === '') {
        return
    }
    dispatch(fetchWhitelistFromContractRequest())
    if (window.web3) {
        try {
            if (address && projectId) {
                return Api.Projects.getUserInfo(smartContractAddress, projectId, address)
                    .then(whitelistRes => {
                        // console.log('whitelistRes', whitelistRes)
                        const whitelist = get(whitelistRes, 'whitelist', false)
                        const rewardedAmount = get(whitelistRes, 'rewardedAmount', '0')
                        const redeemedAmount = get(whitelistRes, 'redeemedAmount', '0')
                        const redeemed = get(whitelistRes, 'redeemed', false)
                        const amount = get(whitelistRes, 'amount', '0')
                        // const maxPayableAmount = get(whitelistRes, 'maxPayableAmount', '0')
                        dispatch(setIsWhiteList(whitelist))
                        dispatch(setRewardedAmount(rewardedAmount))
                        dispatch(setRedeemedAmount(redeemedAmount))
                        dispatch(setRedeemed(redeemed))
                        dispatch(setAmount(amount))
                        // if (maxPayableAmount > 0) {
                        // dispatch(setMaxPayableAmount(maxPayableAmount))
                        // projectContract.methods['getTokenInBNB'](
                        //   fromExponential(new BigNumber(maxPayableAmount).minus(new BigNumber(rewardedAmount))),
                        // )
                        //   .call()
                        //   .then(value => {
                        //     dispatch(setMaxPayableAmountBNB(value))
                        //   })
                        // }
                        dispatch(fetchWhitelistFromContractSuccess(whitelistRes))
                    })
                    .catch(e => {
                        dispatch(fetchWhitelistFromContractFail(e.toString()))
                        dispatch(setIsWhiteList(false))
                        dispatch(setRewardedAmount('0'))
                        dispatch(setRedeemed(false))
                        dispatch(setAmount('0'))
                        dispatch(setMaxPayableAmount(0))
                        dispatch(setMaxPayableAmountBNB(0))
                        dispatch(setMaxAllocation(0))
                    })
            }
        } catch (error) {
            dispatch(fetchWhitelistFromContractFail(error.toString()))
        }
    }
}

const { redeemTokensRequest, redeemTokensSuccess, redeemTokensFail } = createActions({
    REDEEM_TOKENS_REQUEST: () => { },
    REDEEM_TOKENS_SUCCESS: data => ({ data }),
    REDEEM_TOKENS_FAIL: error => ({ error }),
})

export const redeemTokens =
    (isRefund = false) =>
        (dispatch, getState) => {
            dispatch(redeemTokensRequest())

            const { projects, user } = getState()
            const { rewardedAmount, chainId } = user
            const walletAddress = get(user, 'userAccount.accounts.0', '')
            if (projects && projects.project.result && walletAddress) {
                const {
                    contractIsFinished,
                    contractIsFail,
                    smartContractAddress,
                    acceptedTokenDecimals,
                    acceptedTokenSymbol,
                    tokenName,
                    tokenSymbol,
                    tokenDecimals,
                    contractProjectId,
                } = projects.project.result
                if (contractIsFinished || (contractIsFail && isRefund)) {
                    // todo tach ra
                    let log = {
                        chainId,
                        walletAddress,
                        smartContractAddress,
                        acceptedTokenDecimals,
                        acceptedTokenSymbol,
                        tokenName,
                        tokenSymbol,
                        tokenDecimals,
                        eventName: 'redeemTokens',
                        display: isRefund ? 'Refund BNB' : `Claimed ${formatUnits(rewardedAmount, tokenDecimals)} ${tokenSymbol}`,
                    }
                    if (smartContractAddress) {
                        return Api.Projects.redeemTokens(smartContractAddress, walletAddress, contractProjectId, isRefund)
                            .then(response => {
                                log = {
                                    ...log,
                                    ...response,
                                }
                                // console.info('log: ', log)
                                dispatch(redeemTokensSuccess(log))
                                dispatch(fetchWhitelistFromContract(contractProjectId, walletAddress, smartContractAddress))
                            })
                            .catch(error => {
                                dispatch(redeemTokensFail(error))
                                throw error
                            })
                    }
                }
            }

            return Promise.reject('Error')
        }

export const clearUserProjectData = () => dispatch => {
    dispatch(setIsWhiteList(false))
    dispatch(setRewardedAmount('0'))
    dispatch(setRedeemed(false))
    dispatch(setAmount('0'))
    dispatch(setMaxPayableAmount(0))
    dispatch(setMaxPayableAmountBNB(0))
    dispatch(setMaxAllocation(0))
}

/** CONNECT TO WALLET CONNECT **/
export const connectToWalletConnect = () => async dispatch => {
    dispatch(connectWalletRequest())
    try {
        const POLLING_INTERVAL = 12000
        const provider = new WalletConnectProvider({
            rpc: {
                56: 'https://bsc-dataseed1.ninicoin.io',
                97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
            },
            bridge: 'https://bridge.walletconnect.org',
            qrcode: true,
            pollingInterval: POLLING_INTERVAL,
        })

        const supportedChainIds = [56, 97]

        if (!provider.wc.connected) {
            await provider.wc.createSession({
                chainId: supportedChainIds[0],
            })
        }

        //  Enable session (triggers QR Code modal)
        await provider.enable()

        //  Create Web3
        const web3 = new Web3(provider)
        window.web3 = web3

        const accounts = await web3.eth.getAccounts()
        const chainId = await web3.eth.getChainId()
        const bnbBalance = await web3.eth.getBalance(accounts[0])
        const balance = new BigNumber(bnbBalance).toJSON()
        dispatch(connectWalletSuccess())
        dispatch(setUserAccounts({ accounts, balance, chainId: chainId }))
        // dispatch(fetchPoolsPublicDataAsync())

        provider.on('accountsChanged', async accounts => {
            const chainId = await window.web3.eth.getChainId()
            const bnbBalance = await window.web3.eth.getBalance(accounts[0])
            const balance = new BigNumber(bnbBalance).toJSON()
            dispatch(setUserAccounts({ accounts, balance, chainId: chainId }))
            // dispatch(fetchPoolsPublicDataAsync())
        })

        // Subscribe to chainId change
        provider.on('chainChanged', async chainId => {
            // await verifyNetwork()
            const bnbBalance = await window.web3.eth.getBalance(accounts[0])
            const balance = new BigNumber(bnbBalance).toJSON()
            dispatch(setUserAccounts({ balance, chainId: parseInt(chainId) }))
            // dispatch(fetchPoolsPublicDataAsync())
        })

        provider.on('disconnect', () => {
            dispatch(clearUserDataOnDisconnectMetamask())
            dispatch(setUserAccounts({ accounts: [] }))
            dispatch(clearUserProjectData())
            window.web3 = undefined
        })
        return true
    } catch (error) {
        dispatch(connectWalletFail())
        return false
    }
}

export const isInWhitelist = (projectId, walletAddress) => async dispatch => {
    if (!walletAddress || !projectId) {
        return
    }
    return Api.Projects.isInWhitelist(projectId, walletAddress)
        .then(({ data }) => {
            if (data && data.inWhitelist) {
                dispatch(setMaxAllocation(data.maxAllocation))
            } else {
                dispatch(setMaxAllocation(0))
            }
            return data
        })
        .catch(() => {
            // console.error('isInWhitelist', error)
            return {}
        })
}

export const signWallet = (publicAddress, chainId) => {
    //
    return (
        Api.User.signWallet(publicAddress, chainId)
            .then(({ data }) => {
                return data
            })
            // Popup MetaMask confirmation modal to sign message
            .then(async ({ publicAddress, nonce }) => {
                try {
                    const signature = await window.web3.eth.personal.sign(
                        `I am signing my one-time nonce: ${nonce}`,
                        publicAddress,
                        '', // MetaMask will ignore the password argument here
                    )

                    return { publicAddress, signature, nonce }
                } catch (err) {
                    // console.error(err, 'err')
                    throw new Error('You need to sign the message to be able to log in.')
                }
            })
            .catch(error => {
                return Promise.reject(error)
            })
    )
}
// eslint-disable-next-line no-unused-vars
function getContract(projectContractAbi, smartContractAddress) {
    throw new Error('Function not implemented.')
}

// GET network and chain id
const getNetworkInfo = () => {
    const chainId = get(window, 'ethereum.chainId') ?? '0x1'
    const chainIdToNetwork = {
        '0x1': 'ethereum',
        '0x3': 'ethereum',
        '0x38': 'bsc',
        '0x61': 'bsc',
        '0xa86a': 'avax',
        '0xa869': 'avax',
    }
    const network = chainIdToNetwork[chainId]
    return {
        network: network,
        chainId: chainId,
    }
}

export const verifyChainId = chainId => {
    if (typeof chainId !== 'number' && typeof chainId !== 'string') {
        return '0x1';
    }
    if (typeof chainId === 'number') {
        return '0x' + chainId.toString(16);
    }
    if (!chainId.startsWith('0x')) {
        return '0x' + parseInt(chainId.replace(/\D+/g, '')).toString(16);
    }
    return chainId;
};

/* REQUEST UPDATE LOCK BY ID */
const { requestUpdateLockByIdActionRequest, requestUpdateLockByIdActionSuccess, requestUpdateLockByIdActionFail } =
    createActions({
        REQUEST_UPDATE_LOCK_BY_ID_ACTION_REQUEST: () => { },
        REQUEST_UPDATE_LOCK_BY_ID_ACTION_SUCCESS: data => ({ data }),
        REQUEST_UPDATE_LOCK_BY_ID_ACTION_FAIL: error => ({ error }),
    })

export const requestUpdateLockByIdAction = (lockContractAddress, lockDepositId) => dispatch => {
    dispatch(requestUpdateLockByIdActionRequest())

    const { network, chainId } = getNetworkInfo()

    return Api.User.requestUpdateLockByIdRest(network, chainId, lockContractAddress, lockDepositId)
        .then(({ data }) => {
            if (data.error) {
                return dispatch(requestUpdateLockByIdActionFail(data.error))
            } else {
                return dispatch(requestUpdateLockByIdActionSuccess(data.data))
            }
        })
        .catch(error => {
            return dispatch(requestUpdateLockByIdActionFail(error))
        })
}

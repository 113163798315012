import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { EMOTION_LIST } from '../Container/constants';

import Spinner from '../../../components/Spinner';
import Emoji from '../../../components/Emoji';
import EmotionTitle from '../../../components/EmotionTitle';

import angelActive from '../../../assets/emotion/angel-active.png';
import devilActive from '../../../assets/emotion/devil-active.png';

import style from '../Game.module.scss';
import { SPIN_DIRECTION } from '../constants';

const preloadImages = (array) => {
    if (!preloadImages.list) {
        preloadImages.list = [];
    }
    let list = preloadImages.list;
    for (let i = 0; i < array.length; i++) {
        let img = new Image();
        img.onload = function () {
            let index = list.indexOf(this);
            if (index !== -1) {
                list.splice(index, 1);
            }
        };
        img.src = array[i].gif;
        img.style = 'display: none';
        document.body.append(img);
        list.push(img);
    }
};

export default function Container(props) {
    const {
        context,
        disable,
        onSpin,
        canSpin,
        isSpinning,
        direction, // default, balance, left, right
        setDirection,
        isSpinLoading,
        devilBank
    } = props;

    const [devilFrame, setDevilFrame] = useState(0);
    const [angelFrame, setAngelFrame] = useState(0);

    const winner = useSelector((state) => state.game?.prevCycleInfo?.currentCycle?.winner);

    useEffect(() => {
        if (isSpinning) {
            setDirection(SPIN_DIRECTION.BALANCE);
        } else if (winner === '0') {
            setDirection(SPIN_DIRECTION.LEFT);
            return SPIN_DIRECTION.LEFT;
        } else if (winner === '1') {
            setDirection(SPIN_DIRECTION.RIGHT);
            return SPIN_DIRECTION.RIGHT;
        }
    }, [isSpinning, winner]);

    useEffect(() => {
        switch (direction) {
        case SPIN_DIRECTION.LEFT:
            setDevilFrame(2);
            setAngelFrame(1);
            break;
        
        case SPIN_DIRECTION.RIGHT:
            setDevilFrame(1);
            setAngelFrame(2);
            break;
        default:
            setDevilFrame(0);
            setAngelFrame(0);
            break;
        }
    }, [isSpinning, direction]);

    // const startTimer = (frame) => {
    //     timer = setInterval(() => {
    //         stopTimer();
    //         triggerStatus(frame);
    //         frame === 'right' ? setAngelFrame(2) : setDevilFrame(2);
    //     }, 9500);
    // };

    // const stopTimer = () => {
    //     if (timer) clearInterval(timer);
    //     timer = null;
    // };

    // const emotionSelect = (frame) => {
    //     if (frame === 'right') {
    //         startTimer(frame);
    //         setAngelFrame(1);
    //         setDevilFrame(3);
    //     }
    //     if (frame === 'left') {
    //         startTimer(frame);
    //         setDevilFrame(1);
    //         setAngelFrame(3);
    //     }
    // };

    useEffect(() => {
        preloadImages([...EMOTION_LIST.devil, ...EMOTION_LIST.angel]);
    }, []);

    // Fake animation new tab

    // React.useEffect(() => {
    //     setDirection('balance');
    //     setInterval(() => {
    //         setDirection('right');
    //     }, 1500);
    //     const afterChosen = setInterval(() => {
    //         if (afterChosen) {
    //             clearInterval(afterChosen);
    //         }
    //         emotionSelect('right');
    //     }, 2500);
    // }, []);

    // React.useEffect(() => {
    //     setDirection('balance');
    //     setInterval(() => {
    //         setDirection('left');
    //     }, 10000);
    //     const afterChosen = setInterval(() => {
    //         if (afterChosen) {
    //             clearInterval(afterChosen);
    //         }
    //         emotionSelect('left');
    //     }, 9000);
    // }, []);

    // const onSpinner = async () => {
    //     let direction = null,
    //         timerDirection = null;

    //     try {
    //         setDirection('balance');
    //         await GameApi.fetchSpinVRF(addressUser);
    //         const isSpinning = await GameApi.fetchIsSpin();
    //         if (users) {
    //             const checkIndex = await GameApi.fetchCycleIndex();
    //             const afterCycle = await GameApi.fetchCyclePresenter(
    //                 checkIndex
    //             );
    //             direction = handleSpine(isSpinning, afterCycle);
    //             timerDirection = setInterval(
    //                 () => {
    //                     if (timerDirection) {
    //                         clearInterval(timerDirection);
    //                     }
    //                     setDirection(direction);
    //                 },
    //                 direction === 'left' ? 10000 : 1500
    //             );
    //             timerEmotion = setInterval(
    //                 () => {
    //                     if (timerEmotion) {
    //                         clearInterval(timerEmotion);
    //                     }
    //                     emotionSelect(direction);
    //                 },
    //                 direction === 'left' ? 9000 : 2300
    //             );
    //         }
    //     } catch {
    //         setDirection('default');
    //         return null;
    //     }
    // };

    // const onActive = () => {
    //     setEmotionTitle(true);
    // };

    return (
        <>
            <div className={style.wrapperBackground}>
                <Emoji
                    bg={EMOTION_LIST.bg.devil}
                    frame={devilFrame}
                    emotion={EMOTION_LIST.devil}
                />
                <Emoji
                    bg={EMOTION_LIST.bg.angel}
                    type="angel"
                    frame={angelFrame}
                    emotion={EMOTION_LIST.angel}
                />
            </div>
            <div className={style.wrapperSpinner}>
                <Spinner
                    disable={disable}
                    direction={direction}
                    onSpin={onSpin}
                    canSpin={canSpin}
                    isSpinning={isSpinning}
                    isSpinLoading={isSpinLoading}
                />
            </div>

            <div className={style.wrapperEmotionTitle}>
                <EmotionTitle
                    disable={devilFrame === 2 ? true : false}
                    frame={devilFrame}
                    price={devilBank}
                    active={devilActive}
                />
                <EmotionTitle
                    disable={angelFrame === 2 ? true : false}
                    frame={angelFrame}
                    price={context ? context.angelBank : 0}
                    active={angelActive}
                    type="angel"
                    // onClick={onActive}
                />
            </div>
        </>
    );
}

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { GAME_LIST, SPIN_DIRECTION } from './constants';
import ContainerGame from './Container';
import { UseGameHooks } from './useGameHooks';

import GameLayout from '../../layouts/GameLayout';
import CountDown from '../../components/CountDown';
import Helper from '../../components/Helper';
import Dialog from '../../components/Dialog';
import ListHistory from '../../components/ListHistory';
import MasterWallet from '../../components/MasterWallet';
import Api from '../../services/api';

import { ReactComponent as Icon } from '../../assets/svg/format.svg';
import { ALLOWED_NETWORKS } from '../../store/modules/User/constants';
import { setWrongNetwork } from '../../store/modules/User/actions';
import { formatAmount } from '../../utils';

import style from './Game.module.scss';

export default function Game() {
    const dispatch = useDispatch();

    const [disableSpin, setDisableSpin] = useState(false);
    const [realTime, setRealTime] = useState(0);
    const [direction, setDirection] = useState(SPIN_DIRECTION.DEFAULT);

    const chainIdInt = useSelector((state) => state.user?.chainId);
    const wrongNetwork = useSelector(state => state.user?.wrongNetwork);
    const isConnect = useSelector(state => state.user?.connectWallet?.isConnect);
    const account = useSelector(state => state.user?.userAccount?.accounts)[0];
    const gameInfo = useSelector(state => state.game ?? {});
    const isCountDown = useSelector(state => state.game?.isCountDown);
    const devilBankReducer = parseFloat(formatAmount(gameInfo?.devilBank ?? 0) ?? 0);

    const {
        time,
        isModal,
        setIsModal,
        openDialog,
        getTimeCountDown,
        canSpin,
        isSpinning,
        winnerTeam,
        handleOnSpin,
        isSpinLoading,
        isWaitingResult,
        setWaitingResult
    } = UseGameHooks({ account, realTime, isConnect, setDirection, direction });

    const [devilBank, setDevilBank] = useState(null);
    const [isStartCountdown, setIsStartCountdown] = useState(false);

    Api.GameApi.fetchTokenDecimals();

    useEffect(() => {
        if (devilBank === null && devilBankReducer > 0 && !isStartCountdown) {
            setDevilBank(devilBankReducer);
            setIsStartCountdown(true);
        }
    }, [devilBankReducer, devilBank, setDevilBank, isStartCountdown, setIsStartCountdown]);

    useEffect(() => {
        let interval = setInterval(() => {
            setRealTime(prevState => prevState + 1);
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, [realTime, isCountDown]);

    useEffect(() => {
        if (isConnect && chainIdInt) {
            const hexChainId = `${chainIdInt.toString().slice(0,2) === '0x' ? '' : '0x'}${chainIdInt.toString(16)}`;
            const isValid = ALLOWED_NETWORKS.indexOf(hexChainId) !== -1;

            if (!isValid) {
                dispatch(setWrongNetwork(true));
            } else if (isValid) {
                dispatch(setWrongNetwork(false));
            }
        } else if (wrongNetwork) {
            dispatch(setWrongNetwork(false));
        }
    }, [chainIdInt, isConnect]);

    useEffect(() => {
        if (!isWaitingResult) return;

        if (isStartCountdown) {
            let interval = setInterval(() => {
                if (devilBank > 0) {
                    setDevilBank(prev => prev - Math.random() * (devilBankReducer / 10));
                }
            }, 1)
    
            if (devilBank <= 0 && devilBank !== null) {
                setDevilBank(0);
                setIsStartCountdown(false);
                setWaitingResult(false);
                return clearInterval(interval);
            }
    
            return () => {
                clearInterval(interval);
            }
        }
    }, [devilBank, isStartCountdown, setIsStartCountdown, devilBankReducer, isWaitingResult]);

    const getTeamProgress = () => {
        //0: balance, 1: buying, 2: selling

        switch (direction) {
        case 'left':
            return 2;

        case 'right':
            return 1;
        
        default:
            return 0;
        }
    };

    const onContractAddress = () => {
        if (window.location.pathname === '/game') {
            const url = chainIdInt === '0x38'
                ? 'https://bscscan.com/address/0xC5E07D99307ddCafB8799385d3B4A89a0f6FAEbe'
                : 'https://testnet.bscscan.com/address/0xC5E07D99307ddCafB8799385d3B4A89a0f6FAEbe'
            window.open(url, '_blank');
        }
    };

    return (
        <>
            <GameLayout>
                <div className={style.wrapperContentLayout}>
                    <ContainerGame
                        context={gameInfo}
                        setDisable={(value) => setDisableSpin(value)}
                        // triggerStatus={triggerStatus}
                        disable={disableSpin}
                        onSpin={handleOnSpin}
                        canSpin={canSpin}
                        isSpinning={isSpinning}
                        winnerTeam={winnerTeam}
                        isCountDown={isCountDown}
                        direction={direction}
                        setDirection={setDirection}
                        isSpinLoading={isSpinLoading}
                        devilBank={parseFloat(devilBank).toFixed(2)}
                    />
                    <div className={style.wrapperContent}>
                        <div className={style.wrapperCountDown}>
                            {gameInfo && (
                                direction === SPIN_DIRECTION.DEFAULT ? (
                                    <CountDown
                                        dateNow={time > 0 ? time : Date.now()}
                                        hour={
                                            time > 0 && gameInfo
                                                ? gameInfo?.cycle?.duration
                                                : 0
                                        }
                                        // triggerComplete={onComplete}
                                        getTimeCountDown={getTimeCountDown}
                                    >
                                        <Helper text={GAME_LIST[getTeamProgress()].title}>
                                            <div className={style.iconHelper}>
                                                <Icon />
                                            </div>
                                        </Helper>
                                    </CountDown>
                                ) : (
                                    <>
                                        <Helper
                                            text={GAME_LIST[getTeamProgress()].title}
                                            css={GAME_LIST.css.title}
                                        />
                                        <Helper
                                            text={GAME_LIST[getTeamProgress()].subTitle}
                                            css={GAME_LIST.css.subTitle}
                                        />
                                    </>
                                )
                            )}
                        </div>
                        <div className={style.wrapperMasterWallet}>
                            <MasterWallet
                                onPastResult={openDialog}
                                onContractAddress={onContractAddress}
                                context={gameInfo}
                                devilBank={parseFloat(devilBank).toFixed(2)}
                            />
                        </div>
                    </div>
                </div>
            </GameLayout>
            {gameInfo && (
                <Dialog
                    state={isModal}
                    closeModal={() => {
                        setIsModal(false);
                    }}
                >
                    <ListHistory
                        // listHistory={gameInfo.listHistory || []}
                        listHistory={[]}
                    />
                </Dialog>
            )}
        </>
    );
}

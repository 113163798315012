import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ConnectToWalletModal from '../ConnectToWalletModal';
import SwitchNetworkModal from '../Wallet/SwitchNetworkModal';
import { setConnectModalVisible } from '../../store/modules/App/actions';
// import { ALLOWED_NETWORKS } from '../../utils/web3';

export default function ModalContainer() {
    const dispatch = useDispatch();

    const { connectModalVisible } = useSelector((state) => state.app);
    // const { chainId } = useSelector(state => state.wallet?.chainId);

    return (
        <>
            <ConnectToWalletModal
                visible={connectModalVisible}
                onCancel={() => dispatch(setConnectModalVisible(false))}
            />
            <SwitchNetworkModal />
        </>
    );
}

import React from 'react';
import { HeaderContainer } from '../../components/GameHeader';
import cn from 'classnames';

import style from './GameLayout.module.scss';

export default function GameLayout(props) {
    return (
        <div className={cn(style.container)}>
            <HeaderContainer menuOpen={false} />
            <main className={style.main}>{props.children}</main>
        </div>
    );
}

import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import dayjs from 'dayjs';

import { updateCycleInfo, setCountDown } from './actions';

import { fetchUsd } from '../Project/action';
import { getCurrentPrice } from '../../../constants/priceBnb';

import Api from '../../../services/api';
import PresenterABI from '../../../abi/devilflip_presenter.abi.json';
import { contractAddresses } from '../../../utils';
import {
    fetchTokenAddress,
    fetchCycleIndex,
    fetchCyclePresenter,
    fetchAngelBank,
    fetchDevilBank
} from '../../../utils/callHelpers';

export const UseRoundHooks = () => {
    const dispatch = useDispatch();

    const chainId = useSelector(state => state.wallet.chainId);
    const account = useSelector(state => state.user.userAccount.accounts)[0];

    // get contract address 
    const addresses = contractAddresses(chainId);

    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    };

    const getNewCycleInfo = async () => {
        try {
            const presenterContract = new window.web3.eth.Contract(PresenterABI, addresses);

            const decimal = await Api.GameApi.fetchTokenDecimals();
            const usdPrice = await dispatch(fetchUsd());

            const tokenAddress = await fetchTokenAddress(presenterContract);
            const index = await fetchCycleIndex(presenterContract);

            const currentCycle = await fetchCyclePresenter(presenterContract, index);
            const angelBank = await fetchAngelBank(presenterContract);
            const devilBank = await fetchDevilBank(presenterContract);

            const currentPrice = await dispatch(
                getCurrentPrice(
                    tokenAddress,
                    decimal,
                    usdPrice.wbnb.usd,
                    chainId
                )
            );
            const listHistory = await Api.GameApi.fetchHistory(
                index,
                5,
                Api.GameApi.fetchCyclePresenter
            );

            dispatch(updateCycleInfo(
                {
                    angelBank,
                    devilBank,
                    decimal,
                    chainId: chainId,
                    tokenAddress,
                    usdPrice: usdPrice.wbnb.usd,
                    currentPrice,
                    listHistory
                },
                index, 
                currentCycle
            ));

            const now = dayjs();
            const duration = Number(currentCycle?.duration);
            const stageStartObject = dayjs(currentCycle?.startTime * 1000);
            const stageEndObjet = stageStartObject.add(duration, 'second');

            let diffSeconds = stageEndObjet.diff(now, 'second');

            if (diffSeconds > 0) {
                dispatch(setCountDown(true));
                await sleep((diffSeconds + 1) * 1000);
            } else {
                dispatch(setCountDown(false));
                await sleep(3000);
            }
    
            getNewCycleInfo().then().catch();
        } catch (error) {
            return error;
        }
    };

    useEffect(async () => {
        if (chainId && account && window.web3) {
            getNewCycleInfo().then().catch();
        }
    }, [chainId, account, dispatch]);
};
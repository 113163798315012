import { handleActions } from 'redux-actions';
import {persistReducer} from 'redux-persist';
import localForage from 'localforage';

const initialState = {
    index: 0,
    cycle: null,
    angelBank: '0',
    devilBank: '0',
    decimal: '18',
    chainId: '',
    tokenAddress: '',
    usdPrice: 0,
    currentPrice: null,
    listHistory: 0,
    isCountDown: true,
    isAutoSpin: false,
    isManualSpin: false,
    prevCycleInfo: null
};

let roundReducer = handleActions({
    SET_ROUND_DATA: (state, { payload }) => ({
        ...state,
        ...payload.initValue,
        index: payload.index,
        cycle: payload.cycle
    }),

    SET_COUNT_DOWN: (state, { payload }) => ({
        ...state,
        isCountDown: payload
    }),

    SET_AUTO_SPIN: (state, { payload }) => ({
        ...state,
        isAutoSpin: payload
    }),

    SET_MANUAL_SPIN: (state, { payload }) => ({
        ...state,
        isManualSpin: payload
    }),

    SET_PREV_CYCLE_INFO: (state, { payload }) => ({
        ...state,
        prevCycleInfo: payload
    })
}, initialState);

roundReducer = persistReducer({
    key: 'round',
    storage: localForage,
    whitelist: []
}, roundReducer);

export default roundReducer;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LOADING_STATUSES } from '../../constants';
import {
    connectMetaMask,
    connectToWalletConnect,
} from '../../store/modules/User/actions';
import Button from '../Button';
import Modal from '../Modal';
import style from './ConnectToWalletModal.module.scss';
import { CONNECTION_TYPES, PROVIDER_ITEMS } from './constants';
import ProviderList from './ProviderList';

export default function ConnectToWalletModal(props) {
    const dispatch = useDispatch();

    const { wrapperClass, onCancel, visible, ...restProps } = props;

    const [selectedItem, setSelectedItem] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState(null);

    useEffect(() => {
        setSelectedItem(null);
        setLoadingStatus(null);
    }, [visible]);

    const dispatchConnect = () => dispatch(connectMetaMask());
    const dispatchWalletConnect = () => dispatch(connectToWalletConnect());

    const onSelect = (item) => {
        setSelectedItem(item);
        window.localStorage.setItem('connectorId', item.connector);

        if (item.connector === CONNECTION_TYPES.metamask) {
            setLoadingStatus(LOADING_STATUSES.LOADING);
            dispatchConnect().then((res) => {
                if (res) {
                    setLoadingStatus(LOADING_STATUSES.LOADED);
                    onCancel();
                } else {
                    setLoadingStatus(LOADING_STATUSES.ERROR);
                }
            });
        } else if (item.connector === CONNECTION_TYPES.walletconnect) {
            setLoadingStatus(LOADING_STATUSES.LOADING);
            dispatchWalletConnect().then((res) => {
                if (res) {
                    setLoadingStatus(LOADING_STATUSES.LOADED);
                    onCancel();
                } else {
                    setLoadingStatus(LOADING_STATUSES.ERROR);
                }
            });
        } else {
            setLoadingStatus(LOADING_STATUSES.ERROR);
        }
    };

    useEffect(() => {
        if (!selectedItem) {
            setLoadingStatus(null);
        }
    }, [selectedItem]);

    return (
        <Modal
            wrapperClass={wrapperClass}
            title={renderTitle(selectedItem, setSelectedItem)}
            width={390}
            onCancel={onCancel}
            visible={visible}
            {...restProps}
        >
            <ProviderList
                items={PROVIDER_ITEMS}
                selectedItem={selectedItem}
                onSelect={onSelect}
                onRepeat={onSelect}
                requesting={loadingStatus === LOADING_STATUSES.LOADING}
                error={loadingStatus === LOADING_STATUSES.ERROR}
            />
        </Modal>
    );
}

function renderTitle(selectedItem, setSelectedItem) {
    if (selectedItem) {
        return (
            <div style={{ textAlign: 'left' }}>
                <Button
                    wrapperClass={style.back}
                    onClick={() => setSelectedItem(null)}
                    text={'Back'}
                />
            </div>
        );
    }
    return 'Connect to a Wallet';
}
